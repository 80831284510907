// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
import { getFirestore } from 'firebase/firestore';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need

const firebaseConfig = {
  apiKey: "AIzaSyAPTnvbIWyRc51cKU_cICnziiy6rXkTAI0",
  authDomain: "mirror-web-91f40.firebaseapp.com",
  projectId: "mirror-web-91f40",
  storageBucket: "mirror-web-91f40.appspot.com",
  messagingSenderId: "467979291073",
  appId: "1:467979291073:web:895cf67e6fe5cdfa930740"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);