import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './component/login';
import UploadPage from './component/uploadVideo';
import MainPage from './component/main';
import Error from './component/errormsg';
import ViewMyVideo from './component/viewMyVideos';
import ChangePassword from './component/changePassword';
import Admin from './component/admin';
import Submit from './component/submit';
import NewestReels from './newestreels';
import ViewReel from './component/viewReels';


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "changepsw",
    element: <ChangePassword />,
  },
  {
    path: "upload",
    element: <UploadPage />,
  },
  {
    path: "discover",
    element: <ViewMyVideo />,
  },
  {
    path: "reels",
    element: <NewestReels />,
  },
  {
    path: "reel",
    element: <ViewReel />,
  },
  {
    path: "submit",
    element: <Submit />,
  },
  {
    path: "DwI9spy3bYC533sjXsVo",
    element: <Admin />,
  },/*
  {
    path: "*",
    element: <Error />,
  },*/
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
);