import '../App.css';
import React, { useState, useRef, Fragment } from 'react';
import Header from '../header';
import Bottom from '../bottom';
import { Link,useSearchParams } from 'react-router-dom';
import { currentLoginAccount,currentLoginID ,currentLoginPermission} from './login';
import { deleteDoc,onSnapshot,doc,updateDoc, setDoc,getDoc,collection,where,query, getDocs,orderBy,limit,count } from "firebase/firestore";
import { db } from '../server/firebase';
import convertTimestamp from '../convertFirebaseTS';
import {DateToLogDay,InputDateToDate} from '../dateToLogString';
import uuid from 'react-native-uuid';
import GoogleTranslate from '../GoogleTranslate';
import { containsAny } from '../findName';


let usersList = '';
let logsList = '';
let users = [];
let sources = [];
let sourcesList = '';
let localExistDates = [];
let records = [];
let recordsList = '';
let pros = [];
let prosList = '';
let logs = [];
let localExistDatesList = '';
let waitingVideos = [];
let waitingVideosList = '';
let p = 0;
let logPageIcons = '';
let dataPageIcons = '';
let dataNewRegY = 0;let dataNewRegYY = 1;
let dataLoginY = 0;let dataLoginYY = 1;
let datas = [];
let datasList = '';
let videosToBeSet = [];
let datap = 0;
let currentVideoSrcs = [];
const avoid = ['Twitter','mp4'];

function Admin() {

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [logPPP,setLogPPP] = useState(10);
    const [turnToPage,setTurnToPage] = useState(1);
    const [logUserIds, setLogUserIds] = useState('');
    const [logUserEmails, setLogUserEmails] = useState('');

    const [findUserIds, setFindUserIds] = useState('');
    const [findUserEmails, setFindUserEmails] = useState('');

    const [dataFD,setDataFD] = useState('');
    const [dataTD,setDataTD] = useState('');
    const [dataLogin,setDataLogin] = useState(false);

    const [pName,setPName] = useState('');
    const [pSrc,setPSrc] = useState('');

    const [videoUrls,setVideoUrls] = useState('');

    const [avoid,setAvoid] = useState('');
    const [toName,setToName] = useState('');

    const [dataVideos,setDataVideos] = useState(false);

const [copyFrom,setCopyFrom] = useState('');

    const [logFromDate, setLogFromDate] = useState('');
    const [logToDate, setLogToDate] = useState('');
    
    const [list,setList] = useState('');
    const [waitingVideo,setWaitingVideos] = useState('');
    const firstLoad = useRef(false);
    const errorMsg =()=>{
        return(
              (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
        )
      }
    const topMsg = errorMsg(); 
    
    const [queryParameters] = useSearchParams();
    const operUserID = queryParameters.get("id");
    const checkUID = queryParameters.get("uid");
    const action = queryParameters.get("act");
    

if(!firstLoad.current){
    firstLoad.current=true;
    FirstLoad();
}

    
async function getVideo(){
    setLoading(true);
    if(copyFrom===''){
        setError([1,'You must input a website!你必须添加一个来源网站']);
        setLoading(false); 
        return;
    }
    else{
        alert(`Copy from ${copyFrom}`);
    }

}





/*
async function Upload(){
    setLoading(true);
    if(videoUrls===''){
        setLoading(false);
        setError([1,`Please upload a file with links 请上传你含有链接的文档，链接可以通过getVideos脚本获取`]);
        return;
    }
    try{
        const getFile = videoUrls.files[0];
        let reader = new FileReader();
        reader.onload = function(e) {
          const content = reader.result;
          // Here the content has been read successfuly
        let linksListR = content.split(`\n`);
        const sp = linksListR[0].split(`//`)[1].split(` *`)[0];
        const linksList = linksListR.slice(1);
        videosToBeSet = [];
        linksList.forEach((element,i)=>{
            try{
                const obj = JSON.parse(element);
                videosToBeSet.push(obj);
            }
            catch(err){
                console.log(`Error happended when dealing with the ${i}th element: ${err}`);
            }
        })
        setVideoWithUrls(videosToBeSet,sp);
        setError([0,`Sucess! ${linksList.length} videos uploading (Repeated videos will be skipped) \n 上传成功！${linksList.length}支视频等待上传（如果链接重复则会跳过）`]);
        setLoading(false);
    }
    reader.readAsText(getFile);
}
    catch(err){
        setError([1,`Failed to upload:${err}`]);
        setLoading(false);
    }
    
}

async function checkValid(data){
    try{
        const resp = await fetch(data.video_src, {method:"GET", mode:"no-cors"});
    }
    catch (error) {
        // TypeError: Failed to fetch
        console.log('There was an error', error);
      }
}

async function strictCleanRepeated(){
    setLoading(true);
    let exURL = [];
    try{
        const cRef = collection(db,'videos');
        const myQuery = query(cRef,where('video_fname','!=',''));
        const cSnap = await getDocs(myQuery);
        
        let num = 0;
        if(!cSnap.empty){
            cSnap.forEach(e=>{
                const data = e.data();
                if(exURL.includes(data.video_src)){
                    num+=1;
                    deleteDoc(doc(db,'videos',data.video_id));
                }
                else{
                    exURL.push(data.video_src);
                    //checkValid(data);
                    
                }
                
            })
            setError([0,`${exURL.length} links in Total - Detected ${num} videos having repeated urls`]);
        }
        else{
            setError([0,`Not found`]);
        }
        
        
        setLoading(false);
    }
    catch(err){
        setError([1,`Failed to remove repeated videos ${err}`]);
        setLoading(false);
    }
}


async function cleanUnnamed(){
    setLoading(true);
    try{
        const cRef = collection(db,'videos');
        const myQuery = query(cRef,where('video_name','==',''));
        const cSnap = await getDocs(myQuery);
        let num = 0;
        if(!cSnap.empty){
            cSnap.forEach(e=>{
                num+=1;
                const data = e.data();
                deleteDoc(doc(db,'videos',data.video_id));
            })
            setError([0,`Detected ${num} videos without name`]);
        }
        else{
            setError([0,`Not found`]);
        }
        
        
        setLoading(false);
    }
    catch(err){
        setError([1,`Failed to remove unnamed videos ${err}`]);
        setLoading(false);
    }
    


}

async function setVideoWithUrls(urls,sp='unknown'){
    let toAdd = [];
    console.log(`call set video with ${urls.length} urls`);
    setLoading(true);
    try{
        
        urls.forEach(obj=>{
            if(!currentVideoSrcs.includes(obj.url)){
                toAdd.push(obj);
            }
        })
        
    }
    catch(err){
        setError([1,`Failed to collect exist urls:${err}`]);
        setLoading(false);
        return;
    }
    //alert(`toAdd:${toAdd.length}`);
    setError([0,`Success! From ${urls.length} links uploaded ${toAdd.length} videos! (Skipped ${urls.length - toAdd.length} repeated)\n成功! 从 ${urls.length} 个链接中上传 ${toAdd.length} 个视频! (跳过了 ${urls.length - toAdd.length} 个重复值)`]);
    setLoading(false);
    try{
        toAdd.forEach(obj=>{
    const newId = uuid.v4();//id for video
    const newVideo = {
        video_id:newIhided,
        video_name:'',
        video_fname:obj.name,
        video_upload_date:new Date(),
        video_size:0,
        video_watches:0,
        video_starred:0,
        video_owner:currentLoginID,
        video_src:obj.url,
        video_hide:false,
        video_supported:sp
    }
    
    //setDoc to database
    const ref = doc(db,'videos',newId);
    setDoc(ref,newVideo);
    GoogleTranslate(obj.name,newId);
        })
        
    }
    catch(err){
        setError([1,`Failed to upload urls:${err}`]);
        setLoading(false);
    }
    
}
*/

async function VideoOper(id,accpet){
    setLoading(true);
    try{
      const ref = doc(db,'videos',id);
      
      
      if(accpet){
        await updateDoc(ref,{video_hide:false});
        alert(`通过成功！视频已经外放！`);
        setLoading(false);
      }
      else{
        await deleteDoc(ref);
        alert(`驳回成功！视频已被删除！`);
        setLoading(false);
      }
    }
    catch(err){
      alert(`失败！${err}`);
      setLoading(false);
    }
    
  }


async function Accept(url,accpet){
    setLoading(true);
    try{
      const ref = doc(db,'pro_waiting',url);
      const snap = await getDoc(ref);
      const dt = snap.data();
      const newName = dt.name;
      const newUrl = dt.url;
      
      if(accpet){
        await updateDoc(ref,{status:"accepted"});
        const newData = {
            name:newName,
            click:0,
            src:newUrl
        }
        const setRef = doc(db,'promotions',newName);
        await setDoc(setRef,newData);
        alert(`通过成功！`);
        setLoading(false);
      }
      else{
        await updateDoc(ref,{status:"denied"});
        alert(`驳回成功！`);
        setLoading(false);
      }
    }
    catch(err){
      alert(`失败！${err}`);
      setLoading(false);
    }
    
  }





  async function getRec(){
    setLoading(true);
    try{
        
        const ref = collection(db,'pro_waiting');
        const snap = await getDocs(ref);
        records = [];
        snap.forEach(e=>{
          if(e.data().status=='waiting'){
            records.push(e.data());
          }
        })

        recordsList = records.map(e=>
          <tr>
              <td>{e.name}</td>
              <td><Link to={e.url} target='_blank'>{e.url}</Link></td>
              <td>{e.description?e.description:"没有描述"}</td>
              <td><button onClick={()=>Accept(e.name,true)}>Accept</button>|
              <button onClick={()=>Accept(e.name,false)}>Deny</button></td>
          </tr>

        )
        setList(recordsList);
        setLoading(false);
        
    }
    catch(err){
        alert(`失败！${err}`);
        setLoading(false);
    }
    
  }


  async function getWaiting(){
    setLoading(true);
    try{
        
        const ref = collection(db,'videos');
        const myQuery = query(ref,where('video_hide','==',true));
        const snap = await getDocs(myQuery);
        waitingVideos = [];
        if(!snap.empty){
            snap.forEach(e=>{
                if(e.data().video_hide){
                  waitingVideos.push(e.data());
                }
              })
      
              waitingVideosList = waitingVideos.map(e=>
                <tr>
                    <td>{e.video_name}</td>
                    <td><Link to={e.video_src} target='_blank'>点我查看视频</Link></td>
                    <td>{convertTimestamp(e.video_upload_date)}</td>
                    <td><button onClick={()=>VideoOper(e.video_id,true)}>通过</button>|
                    <button onClick={()=>VideoOper(e.video_id,false)}>删除</button></td>
                </tr>
      
              )
              setWaitingVideos(waitingVideosList);
              setLoading(false);
        }
        else{
            alert(`empty`);
            setLoading(false);
        }
        
        
    }
    catch(err){
        alert(`失败！${err}`);
        setLoading(false);
    }}




async function FirstLoad(){
    setLoading(true);
    localExistDates = [];
    let m = 9,y=2023;
    const d = new Date();
    try{
        /*
        while((m<=d.getMonth()+1)||y<d.getFullYear()){
            localExistDates.push(`${y}-${(m>9?m:`0${m}`)}`);
            if(m===12){
                m = 1;y+=1;
            }
            else{
                m+=1;
            }
        }
        
        localExistDatesList = localExistDates.map((message,i)=>
        <option value={message}/>
        )*/
        const ref = doc(db,'ad','linkSource');
        const snap = await getDoc(ref);
        const snapData = snap.data();
        sources = [];
        for(const [key, value] of Object.entries(snapData)){
            sources.push({tag:key,num:value});
        }

            const cRef = collection(db,'videos');
            const myQuery = query(cRef,where("video_src",'!=',''));
        
            const cSnap = await getDocs(myQuery);
            cSnap.forEach(d=>{
                const cData = d.data();
                currentVideoSrcs.push(cData.video_src);
            })
        
        sourcesList = sources.map((e,i)=>
            <tr>
                <td>{i+1}</td>
                <td>{e.tag}</td> <td>{e.num} </td>
            </tr>
        )


        setLoading(false);

    }
    catch(err){
        setError([1,`Failed to find logs collection:${err}`]);
        setLoading(false);
    }
    setLoading(true);
    try{
        
        let ystd = DateToLogDay(d);
        let yystd = DateToLogDay(d,-1);
        let yyystd = DateToLogDay(d,-2);

        //alert(`ystd=${ystd},yystd=${yystd},yyystd=${yyystd}`);
        
        let myQuery;
        const logRef = collection(db,'logs');
        //reg today
        myQuery = query(logRef,where('day','>=',yystd),where('day','<',ystd),where('action','==','register'))
        const tdReg = await getDocs(myQuery);
        dataNewRegY = tdReg.size;
        //reg yesterday
        myQuery = query(logRef,where('day','>=',yyystd),where('day','<',yystd),where('action','==','register'))
        const ystdReg = await getDocs(myQuery);
        dataNewRegYY = ystdReg.size;
        //login today
        myQuery = query(logRef,where('day','>=',yystd),where('day','<',ystd),where('action','==','login'))
        const tdLi = await getDocs(myQuery);
        dataLoginY = tdReg.size;
        //login yesterday
        myQuery = query(logRef,where('day','>=',yyystd),where('day','<',yystd),where('action','==','login'))
        const ystdLi = await getDocs(myQuery);
        dataLoginYY = ystdReg.size;
        setLoading(false);
    }
    catch(err){
        setError([1,`Failed to find logs collection:${err}`]);
        setLoading(false);
    }
}




async function Log(page=1){
    
    setLoading(true);
    const f = localExistDates.indexOf(logFromDate);
    const t = localExistDates.indexOf(logToDate);
    if(f===-1||t===-1){
        setError([1,`You have to select both "FROM" date and "To" date - 请选择开始与结束日期！`]);
        setLoading(false);
        return;
    }
    if(f>t){
        setError([1,`"To" date must be further than "FROM" date - 结束日期必须大于开始日期！`]);
        setLoading(false);
        return;
    }
    const fn = parseInt(`${logFromDate.split('-')[0]}${logFromDate.split('-')[1]}`);
    const tn = parseInt(`${logToDate.split('-')[0]}${logToDate.split('-')[1]}`);
    
    
    
    try{
        let count = 0;
        p = 0;
        logs = [];
        let pages = [];
            const collectionRef = collection(db,'logs');
            let myQuery;
            if(logUserIds===''&&logUserEmails===''){
                myQuery = query(collectionRef,where('month','>=',fn),where('month','<=',tn),orderBy('month','asc'));
                
            }
            else{
                let label = 'id';
                let labelF = logUserIds.split('/');
                if(logUserIds===''){
                    label = 'email';
                    labelF = logUserEmails.split('/');
                }

                myQuery = query(collectionRef,where('month','>=',fn),where('month','<=',tn),where(label,'in',labelF),orderBy('month','asc'));
                
            }
            //conditions
            const logsCollection = await getDocs(myQuery);
            if(!logsCollection.empty){
                logsCollection.forEach(element=>{
                    
                    
                    count += 1;
                    if(count>(page-1)*logPPP&&count<=(page)*logPPP){
                        logs.push(element.data());
                    }
                    if(count%logPPP===0){
                        p+=1;
                        pages.push(count);
                    }
                })
            }
            logsList = logs.map((log,i)=>
                <tr>
                  <td>{i+1}</td>
                  <td>{log.id}</td> <td>{log.email}</td><td>{log.username}</td> <td>{convertTimestamp(log.time)} </td> <td>{log.action}</td><td>{log.before?`[BEFORE]${log.before}`:"-"}</td><td>{log.after?`[AFTER]${log.after}`:"-"}</td><td>{log.log_id}</td>
                </tr>)
            logPageIcons = pages.map((pp,i)=>
            <li>{(page>1&&i===0)?<div><button className='pageBtn' onClick={()=>Log(page-1)}>{`<<`}</button>{'　'}</div>:null}
            {(i+1<3||i+1>(p-3)||i+1>=(page-1)||i+1<=(page+1))?<div><button className='pageBtn' onClick={()=>Log(i+1)}>{`${i+1}`}</button>{'　'}</div>:<div>|</div>}
            {(page<p&&i+1===p)?<div><button className='pageBtn' onClick={()=>Log(page+1)}>{`>>`}</button>{'　'}</div>:null}
            </li>
            )
        
        setError([1,`Found ${count} logs with ${p} pages!`]);
        //setError([1,`${logPageIcons}`]);
        setLoading(false);

    }
    catch(err){
        setError([1,`Failed to find logs:${err}`]);
        setLoading(false);
    }   
}
async function Data(page=1){
    setLoading(true);
    
    try{
        const sd = dataFD;
    const td = dataTD;
    let sdd = InputDateToDate(sd);
    let tdd = InputDateToDate(td);
    
    let dayLeft = (tdd.getTime()-sdd.getTime())/(1000*86400);//error
    datas = [];
    let count=0;
    datap = 0;
    let pages = [];
    
        let myQuery;
        const logRef = collection(db,'logs');
        
        while(dayLeft>0){
            count+=1;
            if(count%logPPP){
                datap+=1;
                pages.push(count);
            }
            if(count>(page-1)*logPPP&&count<=(page)*logPPP){
            const y = DateToLogDay(sdd);
            const z = DateToLogDay(sdd,1);
            let m = {date:y,register:0,login:0,watch_video:0,like_video:0};
            if(dataLogin){
                myQuery = query(logRef,where('day','>=',y),where('day','<',z),where('action','==','register'));
                const queryReg = await getDocs(myQuery);
                m.register = queryReg.size;
                myQuery = query(logRef,where('day','>=',y),where('day','<',z),where('action','==','login'));
                const queryLogin = await getDocs(myQuery);
                m.login = queryLogin.size;

            }
            if(dataVideos){
                myQuery = query(logRef,where('day','>=',y),where('day','<',z),where('action','==','watch_video'));
                const queryReg = await getDocs(myQuery);
                m.watch_video = queryReg.size;
                myQuery = query(logRef,where('day','>=',y),where('day','<',z),where('action','==','like_video'));
                const queryLogin = await getDocs(myQuery);
                m.like_video = queryLogin.size;
            }

            datas.push(m);
            
            }
            sdd.setDate(sdd.getDate()+1);
            dayLeft-=1;
        }
        datasList = datas.map((data,i)=>
            <tr>
                <td>{i+1}</td>
                <td>{data.date}</td> {dataLogin?<td>{data.login} </td>:null}
                {dataLogin?<td>{data.register} </td>:null}
                {dataVideos?<td>{data.watch_video} </td>:null}
                {dataVideos?<td>{data.like_video} </td>:null}
              </tr>
        )
        dataPageIcons = pages.map((pp,i)=>
            <li>{(page>1&&i===0)?<div><button className='pageBtn' onClick={()=>Data(page-1)}>{`<<`}</button>{'　'}</div>:null}
            {(i+1<3||i+1>(p-3)||i+1>=(page-1)||i+1<=(page+1))?<div><button className='pageBtn' onClick={()=>Data(i+1)}>{`${i+1}`}</button>{'　'}</div>:<div>|</div>}
            {(page<p&&i+1===p)?<div><button className='pageBtn' onClick={()=>Data(page+1)}>{`>>`}</button>{'　'}</div>:null}
            </li>
            )

        setLoading(false);

    }
    catch(err){
        setError([1,`Failed to find data:${err}`]);
        setLoading(false);
        return;
    }
}

async function addPromotion(){
    setLoading(true);
    if([pName,pSrc].includes('')){
        setError([1,'You must enter both name and link 你必须输入推广链接名字和对应链接！']);
        setLoading(false);
        return;
    }
    try{
        const ref = doc(db,"promotions",pName);
        const newDoc = {
            name:pName,
            src:pSrc,
            click:0
        }
        await setDoc(ref,newDoc);
        setError([0,'Success 添加成功！']);
        setLoading(false);
    }
    catch(err){
        setError([1,`Falied: ${err}`]);
        setLoading(false);
    }
    
}

async function Download(type,filename){
    setLoading(true);
    let csvSource = '';
    if(type===1){
        //type = 1 means download link sources as txt
        csvSource = sources;
    }
    try{
        const date = new Date();
        let fileContent = `User Sources 用户来源统计（总数)\tDate 日期\t ${date}`;
        fileContent += `\nNo.\tTags来源标签\tNum点击次数`;
        let index = 0;
        csvSource.forEach((link)=>{
          fileContent += `\n${index}\t${link.tag}\t${link.num}`;
          index+=1;
        })
        const blob = new Blob([fileContent], { type: "text/plain" });
    
        const url = URL.createObjectURL(blob);
        const l = document.createElement("a");
    l.download = filename+`${date}`;
    l.href = url;
        l.click();
    
        setLoading(false);
      }
      catch(err){
        setError([1,`Failed to download: ${err}`]);
        setLoading(false);
      }
    return;
}


    async function Confirm(){
        setLoading(true);
        try{
            const docRef = doc(db,'accounts',operUserID);
      
            const docSnap = await getDoc(docRef);
            const docSnapData = docSnap.data();
            let localPermission = docSnapData.permission_groups;
            
            if(action==='ban'){
                localPermission.push('ban');
            }
            else if(action==='unban'){
                localPermission.splice(localPermission.indexOf('ban'),1);
            }
            else if(action==='member'){
                localPermission.splice(localPermission.indexOf('free'),1);
                localPermission.push('member');
            }
            else if(action==='cancel'){
                localPermission.splice(localPermission.indexOf('member'),1);
                localPermission.push('free');
            }
            await updateDoc(docRef,{permission_groups:localPermission});
            setError([0,`Complete!${action} to ${operUserID}`]);
            setLoading(false);
        }
        catch(err){
            setError([1,`Failed to set permission:${err}`]);
            setLoading(false);

        }
    }

    async function ChangeNames(){
        setLoading(true);
        let a = [];
        if(avoid===''){
            setError([1,`你必须填写关键词！`]);
            setLoading(false);
        }
        else{
            a = avoid.split('/').filter(s=>(s!==''&&s!==' '));
        }
        let newName = '';
        if(toName!==''){
            newName = toName;
        }
        try{
            const cRef = collection(db,'videos');
            const myQuery = query(cRef,where('video_name','!=',''));
            const cSnap = await getDocs(myQuery);
            let num = 0;

            if(!cSnap.empty){
                cSnap.forEach(e=>{
                    
                    const data = e.data();
                    
                    
                    if(containsAny(data.video_name,a)){
                        num+=1;
                        const realNewName = newName + '-' +num+ '-' + convertTimestamp(data.video_upload_date);
                        updateDoc(doc(db,'videos',data.video_id),{video_name:realNewName});
                        
                    }
                    
                })
                setError([0,`Detected ${num} videos with invalid name`]);
            }
            else{
                setError([0,`Not found`]);
            }
            
            
            setLoading(false);
        }
        catch(err){
            setError([1,`Failed to change videos names ${err}`]);
            setLoading(false);
        }
    }


    async function Find(){
       

            users =[];
              setLoading(true);
          try {
            const docRef = collection(db,'accounts');
            let myQuery;
            if(findUserIds!==''||findUserEmails!==''){
                if(findUserEmails!==''){myQuery = query(docRef,where("email",'in',findUserEmails.split('/')));}
                if(findUserIds!==''){myQuery = query(docRef,where("id",'in',findUserIds.split('/')));}
              }
              else{
                setError([1,`Please add at least 1 ID to filter!`]);
                setLoading(false);
                return;
              }
            const docSnap = await getDocs(myQuery);
            
            if(docSnap.empty){
                setError([1,`Can not find any user`]);
                setLoading(false);
                return;
            }
            else{

                docSnap.forEach(element => {
                  users.push(element.data());
                });
                usersList = users.map((user,i)=>
              <tr>
                <td>{i+1}</td>
                <td>{user.id}</td> <td>{user.email}</td> <td>{user.username}</td> <td>{convertTimestamp(user.reg_time)} </td><td>{convertTimestamp(user.last_login_time)} </td><td>{user.beans}</td>
                <td>{user.permission_groups.includes('ban')?<Link to={`/DwI9spy3bYC533sjXsVo?uid=${currentLoginID}&id=${user.id}&act=unban`}>UNBAN 解除封号</Link>:<Link to={`/DwI9spy3bYC533sjXsVo?uid=${currentLoginID}&id=${user.id}&act=ban`}>BAN 封号</Link>} | 
                {user.permission_groups.includes('member')?<Link to={`/DwI9spy3bYC533sjXsVo?uid=${currentLoginID}&id=${user.id}&act=cancel`}>Cancel Member 解除会员</Link>:<Link to={`/DwI9spy3bYC533sjXsVo?uid=${currentLoginID}&id=${user.id}&act=member`}>Member 发会员</Link>}
                </td>
              </tr>
            )
            setLoading(false);
            }   
          }
          catch (error) {
            setError([1,`Something went wrong trying to find the documents: ${error}`]);
            setLoading(false);
            }
          
    }
  
    return (((currentLoginPermission!=='admin'&&currentLoginPermission!=='official')||(checkUID&&checkUID!==currentLoginID))?(<Fragment>
        <Header />
        <div className="AccountBox">
            <h1>Oops! Something went wrong</h1>
            <div>
            <Link to="/">Back to homepage</Link>
            </div>
            <br/>
          </div>
        <Bottom />
      </Fragment>):(
    
        <Fragment>
          <Header />
          {topMsg?topMsg:null}
  {loading ? <div className="loadingBox"><h2>Please wait...</h2> </div>: null}
<br/>

    <div className="App-adminbox">
          <br/>
          <h1>Admin Template 管理员后台</h1>
          <br/>
          <h2>Logged in as 登录为 {currentLoginAccount}</h2>
          <br/>
          <br/>
          <br/>
          <div class='line_container'><div class="title_lines"></div><h2>{'　'} {'Promotions 友情链接'} {'　'}</h2></div>
          <br/><br/>
          <br/><br/>
            Name:<input type='text' onChange={(e)=>setPName(e.target.value)}/><br/>
            Link:<input type='text' onChange={(e)=>setPSrc(e.target.value)}/><br/>
            <button onClick={()=>addPromotion()}>Add</button>
          <br/>
          <div class="custom-textbox">
      <h2>收录申请</h2>
      <ul>
      <li><button onClick={()=>getRec()}>获取</button><br/></li></ul>
      {records!=''?
      <table className='styled-table'>
      <thead>
          <tr>
              <th>名字</th>
              <th>链接</th>
              <th>描述</th>
              <th>操作</th>
          </tr>
      </thead>
      <tbody>
          {list}
      </tbody>
  </table>
  :null}
<h2>已收录</h2>
      
      {pros!=''?
      <table className='styled-table'><thead><tr>
      <th>No.</th>
      <th>{"Source Tag/来源标签"} </th><th>{"Clicked/链接点击量"} </th> <th>{"处理/Operation"} </th> 
      </tr></thead><tbody>{prosList}</tbody></table>
  :null}

      
      </div>
          <br/>
          <br/><br/><br/>
          <div class='line_container'><div class="title_lines"></div><h2>{'　'} {'Edit Videos 视频处理'} {'　'}</h2></div>
          <br/><br/>
          <br/><br/>
          <br/>
          
          <br/>
          如果名字包含这些则清除:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setAvoid(e.target.value)}/><br/>
          <br/>
          统一改为这个名字:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setToName(e.target.value)}/><br/>
          <br/>
          <button onClick={()=>ChangeNames()}>Clear Invalid Names</button>
          <p>清除包含对应字符串的名字，注意视频不会被删除，只有名字被清空</p>
          <br/>
          <br/>
          <h2>视频审核</h2>
      <ul>
      <li><button onClick={()=>getWaiting()}>获取</button><br/></li></ul>
      {waitingVideosList!=''?
      <table className='styled-table'>
      <thead>
          <tr>
              <th>名字</th>
              <th>链接</th>
              <th>上传日期</th>
              <th>操作</th>
          </tr>
      </thead>
      <tbody>
          {waitingVideo}
      </tbody>
  </table>
  :null}
          <br/>
          <br/>
          <div class='line_container'><div class="title_lines"></div><h2>{'　'} {'User Sources 来源统计'} {'　'}</h2></div>
          <br/>
          <br/>

          {sources.length>0?<div><button onClick={()=>Download(1,`[兰桂坊]用户来源统计`)}>Download 下载</button><br/><table className='styled-table'><thead><tr>
          <th>No.</th>
          <th>{"Source Tag/来源标签"} </th><th>{"Clicked/链接点击量"} </th>  
          </tr></thead><tbody>{sourcesList}</tbody></table></div>:null}<br/><br/>
          <br/><br/>
          <br/>
          <br/>
          <br/>
          
          <br/>
          <input type='text' onChange={(e)=>setCopyFrom(e.target.value)}></input><button onClick={()=>getVideo()}></button>
          <br/>
          <br/>
          <div class='line_container'><div class="title_lines"></div><h2>{'　'} {'Logs Query 日志查询'} {'　'}</h2></div>
          <br/>
           <br/>
            Id:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setLogUserIds(e.target.value)}/><br/>
            Email:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setLogUserEmails(e.target.value)}/><br/>
            <br/>
            <label>From Month:
<input list="frommonths" onChange={(e) => setLogFromDate(e.target.value)} /></label>
<datalist id="frommonths">
  {localExistDatesList}
</datalist>
            <br/><label>To Month:
<input list="tomonths" onChange={(e) => setLogToDate(e.target.value)} /></label>
<datalist id="tomonths">
{localExistDatesList}
</datalist>
            <br/>
            
            <button onClick={()=>Log()}>Search</button><br/>
            {logs.length>0?<div><tbody><ul>{logPageIcons}</ul></tbody>{p>1?<div><input type="number" max={p} min={1} onChange={(e) => setTurnToPage(e.target.value)}/>{'　'}<button onClick={()=>Log(turnToPage)}>{`GO`}</button></div>:null}<br/><br/><table className='styled-table'><thead><tr>
          <th>No.</th>
          <th>{"Id"} </th><th>{"Email"} </th>  <th>{"Username"} </th> <th>{"Date"} </th> <th>{"Type"} </th><th>{"Before"} </th><th>{"After"} </th><th>{"Log_ID"} </th>
          </tr></thead><tbody>{logsList}</tbody></table></div>:null}
  
          <div class='line_container'><div class="title_lines"></div><h2>{'　'} {'Account Management 账号管理'} {'　'}</h2></div>
          {topMsg?topMsg:null}
  {loading ? <div className="loadingBox"><h2>Please wait...</h2> </div>: null}
          <br/>
          Id:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setFindUserIds(e.target.value)}/><br/>
            Email:<br/><textarea size={50}  placeholder='Divide by /' onChange={(e) => setFindUserEmails(e.target.value)}/><br/>
            <br/>
            <button onClick={()=>Find()}>Find user</button><br/><br/>
            {(action&&operUserID)?<div><br/><h3>{`Confirm operation: ${action} for ${operUserID}`}</h3><br/><Link to='/DwI9spy3bYC533sjXsVo'><button onClick={()=>Confirm()}>Confirm 确认操作</button></Link><br/><br/><Link to='/DwI9spy3bYC533sjXsVo'><button>Cancel 取消操作</button></Link><br/><br/></div>:null}
            {users.length>0?<table className='styled-table'><thead><tr>
          <th>No.</th>
          <th>{"Id"} </th><th>{"Email"} </th>  <th>{"Username"} </th> <th>{"Register"} </th> <th>{"Last login"} </th><th>{"Spring Coins"} </th><th>{"Operation"} </th>
          </tr></thead><tbody>{usersList}</tbody></table>:null}
<br/><div class='line_container'><div class="title_lines"></div><h2>{'　'} {'Daily Data 每日数据'} {'　'}</h2></div>
            <br/><h3>{'昨天新增 new register yesterday'}{':　'}{dataNewRegY}{'　('}{dataNewRegY-dataNewRegYY}{dataNewRegY-dataNewRegYY>0?'↑)':(dataNewRegY-dataNewRegYY===0?'-)':'↓)')}</h3>
            <h3>{'昨天登录 login times yesterday'}{':　'}{dataLoginY}{'　('}{dataLoginY-dataLoginYY}{dataLoginY-dataLoginYY>0?'↑)':(dataLoginY-dataLoginYY===0?'-)':'↓)')}</h3>
            <h3>{'开始日期 From date'}</h3><input type='date' onChange={(e) => setDataFD(e.target.value)}/>
            <h3>{'结束日期 To date'}</h3><input type='date' onChange={(e) => setDataTD(e.target.value)}/>
            <input type="checkbox" onChange={(e) => setDataLogin(e.target.checked)} /><label>login & register</label>
            <input type="checkbox" onChange={(e) => setDataVideos(e.target.checked)} /><label>videos watch & like</label>
            <button onClick={()=>Data(1)}>Search</button><br/>
            {datas.length>0?<div>
                <tbody><ul>{dataPageIcons}</ul></tbody>{p>1?<div><input type="number" max={p} min={1} onChange={(e) => setTurnToPage(e.target.value)}/>{'　'}<button onClick={()=>Data(turnToPage)}>{`GO`}</button></div>:null}
                <table className='styled-table'><thead><tr>
          <th>No.</th>
          <th>{"Date"} </th>{dataLogin?<th>{"Login"} </th>:null}
          {dataLogin?<th>{"Register"}</th>:null}
          {dataVideos?<th>{"Watch_video"} </th>:null}
          {dataVideos?<th>{"Like_video"} </th>:null}
        
          </tr></thead><tbody>{datasList}</tbody></table></div>:null}

<br/><br/>
  </div>

    <br/>
          <Bottom />
        </Fragment>)
      );
}



export default Admin;