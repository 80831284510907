export function containsAny(str, charArray) {
    // 将字符数组中的字符拼接成正则表达式
    const regex = new RegExp(charArray.join('|'));
  
    // 使用正则表达式测试字符串
    return regex.test(str);
  }

export function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}