import '../App.css';
import React, { useState, useEffect, Fragment,useRef } from 'react';
import { Link,useSearchParams } from 'react-router-dom';
import { db } from '../server/firebase';
import { onSnapshot,doc,updateDoc, setDoc,getDoc,collection,where,query, getDocs } from "firebase/firestore";
import Header from '../header';
import Bottom from '../bottom';
import uuid from 'react-native-uuid';
import LoginPageIntro from '../loginIntro';
import { language } from '../header';
export let currentLoginAccount = '';
export let currentLoginID = '';
export let currentLoginPermission = '';
export let currentPurchased = [];

function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState([]);
    const [debug, setDebug] = useState([]);
    
    
    //current user info
    const [currentLogin, setCurrentLogin] = useState('');
    
    const [currentLoginE, setCurrentLoginID] = useState('');
    const [currentPermission, setCurrentPermission] = useState('');
    //mode
    const [queryParameters] = useSearchParams();
    const index = queryParameters.get("current");//1 is sign up 0 or undef is login

    async function createLog(logs,action,video='',before='',after=''){
      const date = new Date();
      let m = date.getMonth()+1;
      let d = date.getDate()+1;
      if(m<=9){m=`0${m}`;}
      if(d<=9){d=`0${d}`;}
      let y = date.getFullYear();
      
        
      const newLoginLogInfo = {
        log_id:`${logs.id}+${date.getTime()/1000}`,
        id:logs.id,
        email:logs.email,
        username:logs.username,
        time:date,
        action:action,
        video:video,
        before:before,
        after:after,
        month:parseInt(`${y}${m}`),
        day:parseInt(`${y}${m}${d}`)
      };
      try{
        const docRef = doc(db,`logs`,`${logs.id}-${date.getTime()}`);
        
        setDoc(docRef,newLoginLogInfo);
        
        
      }
      catch(err){
        setError([1,`Failed to create log: ${err}`]);
      }
      
    
    }
//handle top message

const errorMsg =()=>{
    return(
          (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
    )
  }

  const debugMsg =()=>{
    return(
          (debug[1]!==''&&debug[1]!==undefined)?<div className="msgBox"><div className={debug[0]===1?"errorBox":"processBox"}>{debug[0]===1?"⚠️":"✅"}{debug[1]}</div></div>:null
    )
  }

const topMsg = errorMsg();
const debugMsgbotton = debugMsg();
//check the permission group


// click Sign up button
  async function newAccount() {
    
    setLoading(true);
    //check if the email valid, reference:JavaScript: HTML Form - email validation(https://www.w3resource.com/javascript/form/email-validation.php)
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email===""){
      setError([1,(language==='EN'?'Please enter your email address!':'请您输入您的邮箱')]);
      setLoading(false);
      return;
    }
    if(!email.match(mailFormat)){
      setError([1,(language==='EN'?'You must input a valid email':'您必须以一个有效的邮箱为登录邮箱。')]);
      setLoading(false);
      return;
    }
    

    //check if two passwords match and long enough
    if(password.length<=5){
      setError([1,(language==='EN'?'Your password should be longer than 5 letters!':'您的密码必须长于5位字母或数字。')]);
      setLoading(false);
      return;
    }
    if(password!==passwordConfirm){
      setError([1,(language==='EN'?'You must repeat your password!':'您两次输入的密码不一致。')]);
      setLoading(false);
      return;
    }

    

    //check if the email exist
    

  try {
        const collectionRef = collection(db,'accounts');
        const myQuery = query(collectionRef,where("email",'==',email));
        const snapshot = await getDocs(myQuery);
    if(!snapshot.empty){
      setError([1,(language==='EN'?'This email address have been registered, please login.':'此邮箱已被注册，请登录。')]);
      setLoading(false);
      return;
    }
    
  }
    catch (error) {
      setError([1,`Something went wrong trying to find the documents: ${error}`]);
      setLoading(false);
      return;
    }
    
    //add new account
    
    try {
      const newId = uuid.v4();
      const newAccountInfo = {
        id:newId,
        email:email,
        username: email.split('@')[0],
        password:password,
        reg_time: new Date(),
        last_login_time: new Date(),
        permission_groups:['user','free'],
        status:1, // 1=normal 2=locked 3=disabled my admin
        starred:[],
        videos:[],
        purchased_videos:[],
        expiry_date:(new Date()).getTime(),
        selfie:'./defaultSelfie.png',
        sub:'',
        inbox:[],
        freq_watch:[],
        history:[],
        purchase_history:[],
      }
      const docRef = doc(db, "accounts", newId);
      await setDoc(docRef,newAccountInfo);
      setCurrentLogin(newAccountInfo.username);
      setError([0,(language==='EN'?`Welcome! ${newAccountInfo.username}`:`欢迎! ${newAccountInfo.username}`)]);
      setCurrentLoginID(newAccountInfo.id);
      createLog(newAccountInfo,"register");
      //sendEmail(email,`Welcome to Spring Film Studios`,`Dear ${username}, thanks for joining us!`);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError([1,error]);
    }
    
  }
  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  
    return re.test(input_str);
  }

  // click Sign in button
  async function loginAccount(){
    //check email address
    if(emailLogin===""){
      setError([1,(language==='EN'?'Please enter your email address!':'请输入您的邮箱。')]);
      return;
    }
    
    setLoading(true);
    try {
      const docRef = collection(db,'accounts');
      const myQuery = query(docRef,where('email','==',emailLogin));
      const docSnap = await getDocs(myQuery);
      
          if(docSnap.empty){
            setLoading(false);
            setError([1,(language==='EN'?'Username or email address does not exist.':'您输入的账号不存在。')]);
            return;
          }
          else{
            let userL = [];
            docSnap.forEach(doc => {
              userL.push(doc.data());
              
            })
            let user=userL[0];
            if(user.password!==passwordLogin){
              setLoading(false);
              setError([1,(language==='EN'?'Username or password incorrect.':'账号或密码错误。')]);
              return;
            }
            else{
            if(user.permission_groups.includes('ban')){
              setLoading(false);
              setError([1,(language==='EN'?'Your account was banned from use for some reason.':'您的账号当前被禁止使用。')]);
              return;
            }
            const newLoginInfo = {
              last_login_time: new Date()
            }
            const updateRef = doc(db, "accounts", user.id);
            await updateDoc(updateRef,newLoginInfo);
              setLoading(false);
              setCurrentLogin(user.username);
              setCurrentLoginID(user.id);
              let userUnread = 0;
      const userInbox = user.inbox;
      userInbox.forEach(element=>{
        if(!element.read){
          userUnread+=1;
        }
      })
      if(userUnread>99){
        localStorage.setItem("unread",JSON.stringify('99+'));
      }
      else{
        localStorage.setItem("unread",JSON.stringify(userUnread));
      }
              localStorage.setItem("loginID", JSON.stringify(user.id));
              localStorage.setItem("loginName", JSON.stringify(user.username));
              localStorage.setItem("psw", JSON.stringify(user.password));
        
              createLog(user,"login");
              
              
              
              //alert(`beans:${currentSpring.current}`);
              setError([0,(language==='EN'?`Login success. Welcome!${user.username}.`:`登录成功，欢迎回来！${user.username}.`)]);
            }
          }
      
        
    }
    catch (error) {
      setError([1,`Something went wrong trying to find the documents: ${error}`]);
      }
    
  }

  
  if(currentLogin!==''){currentLoginAccount=currentLogin;currentLoginID=currentLoginE;currentLoginPermission=currentPermission;}


  return currentLoginAccount!==''?(<Fragment><Header />{topMsg?topMsg:null}<br/>{debugMsgbotton?debugMsgbotton:null}<div className="App-loginBox"><br/><br/><h1>{language==='EN'?"Login as":"登录为"} {currentLoginAccount} </h1><br/><h2>{language==='EN'?"Your are":"你的用户组："} {currentLoginPermission} {language==='EN'?"user":"用户"}</h2><br/><br/>
  {currentLoginPermission==='official'?<div><Link to="/upload"><button>{language==='EN'?"Upload video":"上传视频"}</button></Link><br/><br/></div>:null}
  
  <Link to="/discover"><button>❤️‍🔥{language==='EN'?"Discover":"浏览"}</button></Link><br/><br/><br/>
  
  <br/>
  
  <Link to="/changepsw"><button>{language==='EN'?"Change password":"更改密码"}</button></Link><br/><br/><br/>

  <button onClick={function e(){setCurrentLogin('');setError([]);currentLoginAccount='';currentLoginID='';currentLoginPermission='';localStorage.clear();}}>{language==='EN'?'Logout':'登出'}</button>
<br/><br/><br/></div><Bottom /></Fragment>):index?(
<Fragment><Header />
    {topMsg?topMsg:null}
  {loading ? <div className="loadingBox"><h2>{language==='EN'?'Please wait...':'加载中...'}</h2> </div>: null}

<div className="App-loginBox">
<br/><br/>
  <div className='App-loginBoxContainer'>
          <br/>
          <h2>{language==='EN'?"Join us":"加入我们"}</h2>
          <br/>
          
        <input 
          type="text"
          className="form__field"
          placeholder={language==='EN'?"Enter your email":"输入你的邮箱"}
          onChange={(e) => setEmail(e.target.value)}
        /><br/><br/>

        

        <input
          type="password"
          className="form__field"
          placeholder={language==='EN'?"Create a password":'设置一个密码'}
          onChange={(e) => setPassword(e.target.value)}
        /><br/><br/>

        <input
          type="password"
          className="form__field"
          placeholder={language==='EN'?"Confirm your password":'确认密码'}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
          <br/><br/>
            {!loading?<button  onClick={() => newAccount()}>{language==='EN'?'Sign up':'注册'}</button>:<button>{language==='EN'?'Please wait...':'加载中...'}</button>}
          <br/><br/>
        
          <h3>{language==='EN'?'Already have an account? Please ':'已有帐号？请'} <Link to={'/login'}>{language==='EN'?'LOGIN':'登录'}</Link> </h3>
          
         
  <br/></div><br/><br/>
 {/*<img width="30%" height="21.46%" src={process.env.PUBLIC_URL+`/ui/loginIntro.jpg`}/> */} 
           
          
<br/><br/>
  </div><br/>
  <LoginPageIntro />
  <Bottom />
</Fragment>
):(
    
    <Fragment>
      <Header />
      {topMsg?topMsg:null}
    {loading ? <div className="loadingBox"><h2>{language==='EN'?'Please wait...':'加载中...'}</h2> </div>: null}
      <div className="App-loginBox">
      <br/>
      <div className='App-loginBoxContainer'>
          
          
      <br/>
          <h2>{language==='EN'?"Login":"登录"}</h2>
          <br/>
          <input
          type="text"
          placeholder={language==='EN'?"Enter your email":"输入你的邮箱"}
          onChange={(e) => setEmailLogin(e.target.value)}
        /><br/><br/>
  
        <input
          type="password"
          placeholder={language==='EN'?"Enter your password":"输入你的密码"}
          onChange={(e) => setPasswordLogin(e.target.value)}
        />
        <br/><br/>
            <button  onClick={() => loginAccount()}>{language==='EN'?'Sign in':'登录'}</button>
          <br/>
          <br/>
          <h3>{language==='EN'?'Do not have an account? Please ':'还没有帐号？请'}<Link to={'/login?current=1'}>{language==='EN'?'SIGN UP':'注册'}</Link> </h3>
        <br/>
          </div>
          <br/><br/>
          
          <br/><br/>
        </div>
        <LoginPageIntro />
      <Bottom />
    </Fragment>
  );



}

  
  export default Login;

  