import '../App.css';
import React, { useState, useRef, Fragment } from 'react';
import Header from '../header';
import Bottom from '../bottom';
import { doc,setDoc,collection,where,query, getDocs,orderBy,limit,count } from "firebase/firestore";
import { db } from '../server/firebase';



const Submit = () => {   
  
    const [link,setLink] = useState('');
    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    
    function isValidURL(url) {
      // 使用正则表达式匹配URL格式
      const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/\S*)?$/i;
      
      // 检查是否匹配
      return urlPattern.test(url);
    }


  async function submit(){
    try{
        if([link,name,description].includes('')){
            alert(`名字，链接和网站介绍都不能为空！`);
            return;
        }
        if(!isValidURL(link)){
          alert(`链接无效！`);
          return;
        }
        const collectionRef = collection(db,'pro_waiting');
        const myQuery = query(collectionRef,where("url",'==',link));
        const snapshot = await getDocs(myQuery);
    if(!snapshot.empty){
      alert(`申请已收到，请勿重复上传！`);
      return;
    }
        
        const newData = {
            name:name,
            url:link,
            description:description,
            status:"waiting",
            is_member:false
        }
        const setRef = doc(db,'pro_waiting',name);
        await setDoc(setRef,newData);
        alert(`恭喜你，提交成功！审核通过后你的网站会被收录！`);
    }
    catch(err){
        alert(`失败！${err}`);
    }
    
  }

    return (     
    <div>
    <Fragment>
      <Header />
      
      <div className="App-mainPage">
      <div className="App-submit">
      <ul>
        <li>Name 名字:<input type='text' maxLength={200} onChange={(e)=>setName(e.target.value)}/></li><br/>
        <li>Link 链接:<input type='text' maxLength={200} onChange={(e)=>setLink(e.target.value)}/></li><br/>
        <li>Description 描述（250词）:<textarea size={50} maxLength={250} style={{boxSizing:"border-box",height:"30px",width:"100px"}} onChange={(e)=>setDescription(e.target.value)}/></li><br/>
        
        <li><button onClick={()=>submit()}>提交</button><br/></li>
      </ul>
      </div>

    
    </div>
      <Bottom /> 
    </Fragment>
            
    </div>
      ); 
    }; 

export default Submit;