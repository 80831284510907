import '../App.css';
import React, { useState, useEffect, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { currentLoginAccount,currentLoginID,currentLoginPermission } from './login';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../server/firebase";
import Header from '../header';
import Bottom from '../bottom';
import {collection,doc,updateDoc,getDoc,setDoc,getDocs, increment} from "firebase/firestore";
import uuid from 'react-native-uuid';
import LoginPageIntro from '../loginIntro';
import { language } from '../header';
import convertTimestamp from '../convertFirebaseTS';
import { getCurrentDate } from '../dateToLogString';
import { generateRandomId } from '../findName';
import { load } from 'cheerio';
let confirmedUpload = false;

function UploadPage() {
    const [video,setVideo] = useState('');
    const [videos,setVideos] = useState('');
    const [tags,setTags] = useState('');
    const [currentUploading, setCurrentUploading] = useState('');
    const [videoName,setVideoName] = useState('');
    const [videoTags,setVideoTags] = useState('');
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [percentNum, setPercent] = useState(0);
    const [toUpload,setToUpload] = useState(0);
    const [uploaded, setUploaded] = useState(0);
    const [videoPreview, setVideoPreview] = useState('');
    const [tagsOptions, setTagsOptions] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [customInput, setCustomInput] = useState('');
    const [subTags, setSubTags] = useState([]);
    
    useEffect(() => {
      const loadTagsOptions = async () => {
       
        const querySnapshot = await getDocs(collection(db, "keywords"));
        const tags = querySnapshot.docs.map(doc => ({
          name: doc.data().name,
          subTags: doc.data().subTags,
        }));
        setTagsOptions(tags);
      };
  
      loadTagsOptions();
    }, []);


    useEffect(() => {
      if (selectedTag !== '' && selectedTag) {
        const tag = tagsOptions.find(tag => tag.name === selectedTag);
        setSubTags(tag ? tag.subTags : []);
      } else {
        setSubTags([]);
      }
    }, [selectedTag, tagsOptions]);


    const errorMsg =()=>{
      return(
            (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
      )
    }
  let userVideoMapping = {};
  const topMsg = errorMsg();
  async function createLog(logs,action,video='',before='',after=''){
    const date = new Date();
      let m = date.getMonth()+1;
      let d = date.getDate()+1;
      if(m<=9){m=`0${m}`;}
      if(d<=9){d=`0${d}`;}
      let y = date.getFullYear();
      

      const newLoginLogInfo = {
        log_id:`${logs.id}+${date.getTime()/1000}`,
        id:logs.id,
        email:logs.email,
        username:logs.username,
        time:date,
        action:action,
        video:video,
        before:before,
        after:after,
        month:parseInt(`${y}${m}`),
        day:parseInt(`${y}${m}${d}`)
      };
    try{
      const docRef = doc(db,`logs`,`${logs.id}-${date.getTime()}`);
      
      setDoc(docRef,newLoginLogInfo);
      
      
    }
    catch(err){
      setError([1,`Failed to create log: ${err}`]);
    }
    
  
  }
    async function addToUserLib(name,size,url,url5,id,tags,dataType){
        setLoading(true);
        
        try{
          userVideoMapping={};
          userVideoMapping = {
            video_id:id,
            video_name:name,
            video_upload_date:new Date(),
            video_size:size,
            video_watches:0,
            video_starred:0,
            video_owner:currentLoginID,
            video_src:url,
            video_trailer:url5,
            video_tags:[selectedTag,...tags.split(",").filter(subTag => subTag.trim() !== '')],
            video_hide:currentLoginPermission==='official'?false:true
          }
           
          const videoRef = doc(db, dataType, id);
          await setDoc(videoRef,userVideoMapping);
          
          //alert(`${JSON.stringify(userVideoMapping)}`);
          const docRef = doc(db, "accounts", currentLoginID);
          const docSnap = await getDoc(docRef);
          let user = docSnap.data();
          let currentVideoList = user.videos;
          userVideoMapping.video_src = url;
          currentVideoList.push(userVideoMapping);
          const newInfo = {
            videos: currentVideoList
          }
          
          updateDoc(docRef,newInfo);
          //Add to single video database
          createLog(user,"upload",id);
          setVideo('');
          setVideoName('');
          
          //Clear
          
          
          setLoading(false);
          confirmedUpload = false;
          return;
        }
        catch (error) {
          setError([1,`Failed to add video to user's folder: ${error},current login ID = ${currentLoginID}`]);
          alert(`Failed to add video to user's folder: ${error},current login ID = ${currentLoginID}`)
          }
        return;
    }
  
    function uploadFile(fileobj,storageRef,ctype,folderId,isMain=false,dataType='',m=false){
      let {
        name,
        size,
        type
      } = fileobj;
      
      size = size / 1000000;
      setCurrentUploading(dataType);
      let collection_target = 'videos';
      //alert(`${name}.${type} : having ${size} MB`);
      if(size>4096){
        setError([1,(language==='EN'?`Your file ${name} is too large, please upload a file less than 4GB`:`您的文件 ${name} 太大了，请上传不高于4GB的文件`)]);
        setLoading(false);
        return;
      }
      try{
        if(size < 5){
          collection_target = 'reels';
        }
        const metadata = {
        contentType: ctype,
        'uploader': currentLoginAccount,
      };
      const uploadTask = uploadBytesResumable(storageRef, fileobj,metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
 
            // update progress
            setPercent(percent);
        },

        (err) => alert(err),
        () => {
            // download url
            setUploaded(uploaded+1);
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              if(m){
                const rid = generateRandomId(10);
                const defaultName = '#网友投稿-' + rid + '-' + getCurrentDate();
                addToUserLib(defaultName,size,url,'',folderId,customInput,collection_target);
              }
              else{
                if(videoName!==''){addToUserLib(videoName,size,url,'',folderId,customInput,collection_target);}
                else{
                  try{
                    const defaultName = '#网友投稿-' +currentLoginAccount+ '-' + getCurrentDate();
                    addToUserLib(defaultName,size,url,'',folderId,customInput,collection_target);
                  }
                  catch(err){
                    alert(`${err}`);
                  }
                  
                }
                
              }
              
              //reset the input to avoid repeat
              /*
              if(uploaded >= toUpload){
                setVideoPreview(url);
              } */
              
              setCurrentUploading('');
              setLoading(false);

              return;
            });
        }
    );
      }
      catch(err){
        setError([1,`Failed to get user permission group: ${err}`]);
        setLoading(false);
        return;
      }

    }

    async function uploads(){
      if(!loading){
        setLoading(true);
      
        //which folder
        let userFolder = 'UserVideos';
          if(currentLoginPermission==="official"){
            userFolder = 'OfficialVideos';
        }
        let videoObj = videos.files;
        //alert(`Type of videoPObjs: ${typeof(videoPObjs)}`);
        setUploaded(0);
        setToUpload(videoObj.length);
        try{
          for(let i = 0;i<videoObj.length;i++){
            const newId = uuid.v4();//id for video
          //Main video
          const storageRefVideo = ref(storage, `/${userFolder}/${currentLoginID}/${newId}/complete.mp4`);
          uploadFile(videoObj[i],storageRefVideo,'video/mp4',newId,true,'complete',true);
        }
          
        }
        catch(err){
          setError([1,`Failed to call upload function: ${err}`]);
          setLoading(false);
          return;
        }
      }
      else{
        alert(`请勿重复点击`)
      }
      
    }

    async function upload(){
      if(!loading){
      setLoading(true);
      const newId = uuid.v4();//id for video
      //which folder
      let userFolder = 'UserVideos';
        if(currentLoginPermission==="official"){
          userFolder = 'OfficialVideos';
      }
      let videoObj = video.files[0];

      //Main video
      const storageRefVideo = ref(storage, `/${userFolder}/${currentLoginID}/${newId}/complete.mp4`);
      try{
        uploadFile(videoObj,storageRefVideo,'video/mp4',newId,true,'complete');
      }
      catch(err){
        setError([1,`Failed to call upload function: ${err}`]);
        setLoading(false);
        return;
      }
    }
    else{
      alert(`请勿重复点击`)
    }
    }
    return currentLoginAccount===''?(
      <Fragment>
        <Header />
        <div className="App-loginBox">
        <h2>{language==='EN'?`Oops, you havn't login yet...`:'噢！您还没有登录呢...'}</h2>
              <br/><br/>
              <h2>{language==='EN'?`Please`:'请'} <Link to="/login">{language==='EN'?`Login or sign up`:'登录/注册'}</Link></h2>
              <br/>
            </div>
            <LoginPageIntro />
            <Bottom />
      </Fragment>
    )
    :(

        <Fragment>
          <Header />
          <div className="App-loginBox">
          {topMsg?topMsg:null}

{loading ? <div className="loadingBox"><h2>{language==='EN'?'Please wait...':'加载中...'}{currentUploading!==''?<div>{language==='EN'?'Uploading':'正在上传'}...</div>:null}{uploaded?uploaded:0}/{toUpload?toUpload:0} {language==='EN'?'done':'已完成'}</h2> </div>: null}

              {!videoPreview?<h1>{language==='EN'?'Welcome!':'欢迎！'} {currentLoginAccount}</h1>:<h1>{language==='EN'?'Great!':'好耶！'} {currentLoginAccount}, {language==='EN'?'upload success!':'上传成功！'}<br/><Link to="/discover">{language==='EN'?'Discover':'浏览视频'}</Link> {language==='EN'?' or upload another one!':'或上传新的一个'}</h1>}
              <div>
              {<div>{language==='EN'?'Upload your complete video:':'上传全片资源:'}<p><input type="file" accept="video/*" onChange={(e) => setVideo(e.currentTarget)}></input></p><br/><br/></div>}
              {currentLoginPermission==='official'?<div>{language==='EN'?'Upload many videos:':'上传多个资源:'}<p><input type="file" accept="video/*" onChange={(e) => setVideos(e.currentTarget)} multiple></input></p><br/><br/></div>:null}
              
              <br/>
              {<div>
      <select className='tagSelect' value={selectedTag} onChange={e => setSelectedTag(e.target.value)}>
        {tagsOptions.map(option => (
          <option key={option.name} value={option.name}>{option.name}</option>
        ))}
        <option key="" value="自定义...">自定义...</option>
      </select>
      {subTags.map(subTag => (
      <button className='tagSelectBtn' key={subTag} onClick={() => setCustomInput(prev => `${prev}${subTag}, `)}>{subTag}</button>
    ))}

        <input type="text" value={customInput} onChange={e => setCustomInput(e.target.value)} placeholder="用英文逗号分隔" />
      
    </div>}
              <br/>
              {<div><h5>{language==='EN'?'Name your video!':'视频名字'}<input type="text" maxLength={120} onChange={(e) => setVideoName(e.target.value)}></input></h5></div>}
              {video?<button  onClick={() => upload()}>{language==='EN'?'Upload':'上传'}</button>:null}<br/>
              {videos?<button  onClick={() => uploads()}>{language==='EN'?'Upload many videos':'上传很多视频'}</button>:null}<br/>
              {videoPreview?<video autoplay playsinline width="320" height="240" controls><source src={videoPreview} type="video/mp4"></source><source src={videoPreview} type="video/ogg"></source>Your browser do not support preview</video>:null}

          <br/>
              </div>
              
            </div>
            <Bottom />
        </Fragment>
      );
}




export default UploadPage;