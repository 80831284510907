import './App.css';
import React, { useState, useEffect, Fragment } from 'react';

function LoginPageIntro() {

  const path = 'long';//Used for advertisements
    return (
    
        <Fragment>
          <div className="App-loginIntroBox">
          
            
          </div>
        </Fragment>
      );
}



export default LoginPageIntro;