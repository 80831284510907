import '../App.css';
import React, { useState, useEffect, Fragment } from 'react';
import Header from '../header';
import Bottom from '../bottom';
import { Link } from 'react-router-dom';
import { currentLoginAccount,currentLoginID ,currentLoginPermission} from './login';
import { onSnapshot,doc,updateDoc, setDoc,getDoc,collection,where,query, getDocs } from "firebase/firestore";
import { db } from '../server/firebase';

function ChangePassword() {


    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setsuccess] = useState(false);

    const [oldPsw, setOldpsw] = useState('');
    const [newPsw, setNewPsw] = useState('');
    const [newPswConfirm, setNewPswConfirm] = useState('');


    const errorMsg =()=>{
        return(
              (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
        )
      }
    const topMsg = errorMsg(); 

    async function changePsw(){


        if(newPsw.length<=5){
            setError([1,'Your new password should be longer than 5 letters!']);
            setLoading(false);
            return;
          }
        if(newPsw!==newPswConfirm){
        setError([1,'You must repeat your new password!']);
        setLoading(false);
        return;
        }

        setLoading(true);
    try {
      const docRef = doc(db,'accounts',currentLoginID);
      
      const docSnap = await getDoc(docRef);
      const docSnapData = docSnap.data();
      const originPsw = docSnapData.password;
      if(oldPsw!==originPsw){
        setError([1,'You must enter your old password correctly!']);
        setLoading(false);
        return;
      }
      else{
        await updateDoc(docRef,{password:newPsw});
        setError([0,'Your password has changed, you have been logged off. Please login again!']);
        setsuccess(true);
        setError([]);currentLoginAccount='';currentLoginID='';currentLoginPermission='';localStorage.clear();
        setLoading(false);
        return;
      }
      
        
    }
    catch (error) {
      setError([1,`Something went wrong trying to find the documents: ${error}`]);
      }
    }
    
  
    return (
    
        <Fragment>
          <Header />
          {topMsg?topMsg:null}
  {loading ? <div className="loadingBox"><h2>Please wait...</h2> </div>: null}
<br/>

    {success?<div className="App-loginBox">
          <br/>
          <h2>Log in again with your new password</h2>
          <br/>
         
          <br/><br/>
            
            <Link to="/login">Back to login</Link>
          <br/>
        
         
<br/><br/>
  </div>:<div className="App-loginBox">
          <br/>
          <h2>Changing password for {currentLoginAccount}</h2>
          <br/>
          
        <input 
          type="password"
          className="form__field"
          placeholder={"Enter your old password"}
          onChange={(e) => setOldpsw(e.target.value)}
        /><br/><br/>
        <input
          type="password"
          className="form__field"
          placeholder={"Create a  new password"}
          onChange={(e) => setNewPsw(e.target.value)}
        /><br/><br/>
        <input
          type="password"
          className="form__field"
          placeholder={"Confirm your new password"}
          onChange={(e) => setNewPswConfirm(e.target.value)}
        />
          <br/><br/>
            <button  onClick={() => changePsw()}>Change</button>
            <br/><br/>
            <Link to="/login">Back</Link>
          <br/>
        
         
<br/><br/>
  </div>}

    <br/>
          <Bottom />
        </Fragment>
      );
}



export default ChangePassword;