export default function permissionSign(group){
    if(group.includes('admin')){
      return('admin');
    }
    if(group.includes('official')){
      return('official');
    }
    if(group.includes('trial')){
      if(group.includes('yes')){
        return('trial-yes');
      }
      else{
        return('trial-no');
      }
    }
    if(group.includes('free')){
      return('free');
    }
    if(group.includes('member')){
      return('member');
    }
    
    return('empty');
  }