import '../App.css';
import React, { useState, useEffect, Fragment,useRef } from 'react';
import {Link} from 'react-router-dom';
import { currentLoginAccount,currentLoginID, currentLoginPermission } from './login';
import {
  useSearchParams
} from "react-router-dom"
import Header from '../header';
import Bottom from '../bottom';
import convertTimestamp from '../convertFirebaseTS';
import { db, storage } from "../server/firebase";
import {doc,updateDoc,setDoc,getDocs,getDoc,collection,where,query,orderBy, limit,increment,deleteDoc} from "firebase/firestore";
import { ResetTimers } from '../header';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import HoverVideoPlayer from 'react-hover-video-player';
import { language } from '../header';
import Grid from '@mui/material/Unstable_Grid2'; 
import isMobileDevice from '../getUserClient';

export let currentPurchasingVideo = '';


const mobile = isMobileDevice();
let newLinks = {};
let listItems = '';
let videoCountTotal = 0;
let skipped = {
  video:true
}
let hottestListItems = [];
let currentVideoListNew = [];
let popLink = ['https://landing.blackmaleme.com/?ats=eyJhIjo5NDk5MzksImMiOjYxNjAwNTQyLCJuIjo1OSwicyI6NTExLCJlIjo5MjMxLCJwIjoxMX0=&atc=Default_Adenvns',
'https://www.nutaku.net/signup/landing/project-qt/2/?ats=eyJhIjo5NDk5MzksImMiOjYxNjAwNTQyLCJuIjoxLCJzIjoxLCJlIjo5MjM4LCJwIjoyfQ==&atc=Default_Adenvns',
'https://landing.hentaipros.com/?ats=eyJhIjo5NDk5MzksImMiOjYxNjAwNTQyLCJuIjoyNSwicyI6MjI4LCJlIjo4OTk2LCJwIjoyfQ==&atc=Default_Adenvns',
'https://landing.bangbrosnetwork.com/?ats=eyJhIjo5NDk5MzksImMiOjYxNjAwNTQyLCJuIjoxMzAsInMiOjY5MywiZSI6MTA2NzMsInAiOjExfQ==&atc=Default_Adenvns',
];


function ViewReel() {
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentView, setCurrentView] = useState('');
    const [currentPlayingInfo, setCurrentPlayingInfo] = useState('');
    const [editinginfo, setEditingInfo] = useState('');
    const ownerInfo = useRef('');
    const [newVideo,setNewVideo] = useState('');
  
    const [currentUploading, setCurrentUploading] = useState('');
    const [newVideoName,setNewVideoName] = useState('');
    const [newName,setNewName] = useState('');
    const [newDesc,setNewDesc] = useState('');
    const [newTags,setNewTags] = useState('');
    const [lim,setLimit] = useState(18);
  const firstLoadDone = useRef(false);
  const checked = useRef(false);
  const lackedCoin = useRef(0);
    const [percentNum, setPercent] = useState(0);
const [pauseAD,setPauseAD] = useState(false);
const [report,setReport] = useState(false);
const [reason,setReason] = useState('');
    const [canShow, setCanShow] = useState(false);
    const [adLink, setAdLink] = useState('');
    const videoSize = mobile?4:2;
  const videoClass = mobile?'videoContainer-M':'videoContainer'
    //const videoListHTML = '';
    const errorMsg =()=>{
      return(
            (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
      )
    }
  const topMsg = errorMsg();
  let currentVideoList = [];
  let currentUserVideoList = [];  
  let old = '';
  let userVideoMapping = {};
  
  const [queryParameters] = useSearchParams();
  const videoID = queryParameters.get("video");

  async function HandleReport(){
    setLoading(true);
    try{
      if(reason!==''){
        const rpRef = doc(db,"reels",videoID);
        const newSource = {
          report:increment(1)
        }
        updateDoc(rpRef,newSource);

        setError([0,(language==='EN'?`Report submitted!`:`举报提交成功！`)]);
        setLoading(false);
      }
      else{
        setError([1,(language==='EN'?`Please add the reason of report.`:`请输入举报原因。`)]);
        setLoading(false);
      }
    }
    catch(err){
      setError([1,`Failed to submit report: ${err}`]);
      setLoading(false);
      }
      
    return;
    }
  



  async function addToUserLib(size,url,id,dataType){
    setLoading(true);
    
      
    //alert(`Triggered addToUserLib ${id}`);
      
    const docRef = doc(db, "accounts", currentLoginID);
    const docSnap = await getDoc(docRef);
    if(!userVideoMapping.video_id){
      

      currentUserVideoList  = [];
      //alert(`got user video length ${currentUserVideoList.length}`);
      

      
      
      
      userVideoMapping = {
        video_id:id,
        video_name:newVideoName?newVideoName:old.video_name,
        video_size:size?size:old.video_size,
        video_watches:old.video_watches,
        video_starred:old.video_starred,
        video_owner:old.video_owner,
        video_src:old.video_src,
        video_hide:false
      }
      
    }
    try{
    
      if(dataType==='complete'){
        userVideoMapping.video_src = url;
      
      }
       
      if((userVideoMapping.video_cover!==''||skipped.videoCover)&&(userVideoMapping.video_src!==''||skipped.video)){
      //alert(`current video list long:${currentUserVideoList}`)
      
      currentUserVideoList.push(userVideoMapping);
      const newInfo = {
        videos: currentUserVideoList
      }
      //alert(`new info: ${newInfo.videos}`);
      await updateDoc(docRef,newInfo);
      const videoRef = doc(db, "reels", id);
      await updateDoc(videoRef,userVideoMapping);//Add to single video database
      //createLog(user,"edit",id);
      createLog(docSnap.data(),"modify",id,old,userVideoMapping);
      setNewVideoName('');
      userVideoMapping={};
      currentUserVideoList=[];
      skipped = {
        video:true
      }
      //Clear
      }
      setError([0,`Modification saved!`]);
      setCurrentPlayingInfo('');readUserLib();
      //alert(`Successful modify on id ${id}`);
      setLoading(false);
      return;
    }
    catch (error) {
      setError([1,`Failed to add video to user's folder: ${error},current login ID = ${currentLoginID}`]);
      }
    return;
}



async function UploadLinks(){
  setLoading(true);
  try{
    const linkRef = doc(db,'ad','poplinks');
    const file = adLink.files[0];
    let reader = new FileReader();
    reader.onload = function(e) {
      const content = reader.result;
      // Here the content has been read successfuly
    const linksList = content.split(`\n`).slice(1);
    newLinks = {
      links:linksList
    }
    updateDoc(linkRef,newLinks);
    setError([0,`Success! Your file contains ${linksList.length} link(s)!`]);
    setLoading(false);
  }
  reader.readAsText(file);
  
    


  }
  catch(err){
    setError([1,`Failed to upload: ${err}`]);
    setLoading(false);
  }

}

async function DownloadLinks(){
  setLoading(true);
  try{
    const linkRef = doc(db,'ad','poplinks');
    const linkRefSnap = await getDoc(linkRef);
    const linkRefSnapData = linkRefSnap.data();
    const links = linkRefSnapData.links;
    let fileContent = `===pop-up links below,divided by linebreak 下面是弹窗广告页面的链接随机池，添加链接前要空行啊===`;
    links.forEach((link)=>{
      fileContent += `\n${link}`;
    })
    const blob = new Blob([fileContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const l = document.createElement("a");
l.download = "ad-links.txt";
l.href = url;
    l.click();
    
    setLoading(false);
  }
  catch(err){
    setError([1,`Failed to download: ${err}`]);
    setLoading(false);
  }
}



  function uploadFile(fileobj,storageRef,ctype,folderId,isMain=false,dataType=''){
    let {
      name,
      size,
      type
    } = fileobj;
    size = size / 1000000;
    setCurrentUploading(dataType);
    //alert(`${name}.${type} : having ${size} MB`);
    if(size>4096){
      setError([1,(language==='EN'?`Your file ${name} is too large, please upload a file less than 4GB`:`您的文件 ${name} 太大了，请上传不高于4GB的文件`)]);
      setLoading(false);
      return;
    }
    try{

      const metadata = {
      contentType: ctype,
      'uploader': currentLoginAccount,
    };
    const uploadTask = uploadBytesResumable(storageRef, fileobj,metadata);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
      },

      (err) => alert(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {

            addToUserLib(size,url,folderId,dataType);
            //reset the input to avoid repeat
            
            setCurrentUploading('');
            setLoading(false);

            return;
          });
      }
  );
    }
    catch(err){
      setError([1,`Failed to get user permission group: ${err}`]);
      setLoading(false);
      return;
    }

  }
  async function createLog(logs,action,video='',before='',after=''){
    //alert(`Called createlog ${action}`);
    const date = new Date();
      let m = date.getMonth()+1;
      let d = date.getDate()+1;
      if(m<=9){m=`0${m}`;}
      if(d<=9){d=`0${d}`;}
      let y = date.getFullYear();
      
        
      const newLoginLogInfo = {
        log_id:`${logs.id}+${date.getTime()/1000}`,
        id:logs.id,
        email:logs.email,
        username:logs.username,
        time:date,
        action:action,
        video:video,
        before:before,
        after:after,
        month:parseInt(`${y}${m}`),
        day:parseInt(`${y}${m}${d}`)
      };
      try{
      const docRef = doc(db,`logs`,`${logs.id}-${date.getTime()}`);
      
      await setDoc(docRef,newLoginLogInfo);
      
      
    }
    catch(err){
      setError([1,`Failed to create log: ${err}`]);
    }
    
  
  } 

  async function FirstTimeLoad(){
    try{
      setLoading(true);
      const videoCollRef = collection(db, "reels");
      const videoCount = await getDocs(query(videoCollRef));
      videoCountTotal = videoCount.size;
      
      //const myQuery = query(videoCollRef, orderBy('video_watches','desc'));
      
      currentVideoListNew = [];
      if(!videoCount.empty){
        videoCount.forEach(doc => {
          const getVideo = doc.data();
          if(!getVideo.video_hide){currentVideoListNew.push(getVideo);//alert(`getVideo:${getVideo.video_id}`)
        }
          
          
        });
  const linkRef = doc(db,'ad','poplinks');
    const linkRefSnap = await getDoc(linkRef);
    const linkRefSnapData = linkRefSnap.data();
    
    if(linkRefSnapData.links){popLink=linkRefSnapData.links;}
        
        hottestListItems = currentVideoListNew.map((video,i) =>
        (i>lim-1)?null:
        <Grid item xs={2}><div className={videoClass}>
          <Link to={`/discover?video=${video.video_id}`}><HoverVideoPlayer
        videoSrc={(
          <>
          <source src={video.video_src} type="video/mp4" />
          <source src={video.video_src} type="video/ogg" />
          </>
        )}
        style={{
          width:'100%',height:'100%'
        }}
        sizingMode='container'
        pausedOverlay={(
          <div>
      <div className="coverTitle">
    <h1>{video.video_name}</h1>
    </div>
    </div>
        )}
        />
        </Link>
        </div>
        </Grid>
      );
      }
      setLoading(false);
    }
    catch(err){
      setError([1,`Failed to load default video list: ${err}`]);
    }
  }
  if(!firstLoadDone.current){
    firstLoadDone.current = true;
    FirstTimeLoad();
  }

  function ResetList(num=18){
    hottestListItems = currentVideoListNew.map((video,i) =>
        (i>num-1)?null:
        <Grid item xs={2}><div className={videoClass}>
          <Link to={`/discover?video=${video.video_id}`}><HoverVideoPlayer
        videoSrc={(
          <>
          <source src={video.video_src} type="video/mp4" />
          <source src={video.video_src} type="video/ogg" />
          </>
        )}
        style={{
          width:'100%',height:'100%'
        }}
        sizingMode='container'
        pausedOverlay={(
          <div>
      <div className="coverTitle">
    <h1>{video.video_name}</h1>
    </div>
    </div>
        )}
        />
        </Link>
        </div>
        </Grid>
      );
    
  }

  async function Delete(id){
    setLoading(true);
    try{
      const docRef = doc(db,'videos',id);
      const docUserRef = doc(db,'accounts',currentLoginID);
      const docUserSnap = await getDoc(docUserRef);
      let afterDelete = [];
      updateDoc(docUserRef,{videos:afterDelete});
      updateDoc(docRef,{video_hide:true});
      setLoading(false);
    }
    catch(err){
      setError([1,`Failed to delete file ${id}:${err}`]);
      setLoading(false);
    }
  }

  async function upload(id){
    setLoading(true);
    const newId = id;//id for video
    //which folder
    let userFolder = 'UserVideos';
      if(currentLoginPermission==="official"){
        userFolder = 'OfficialVideos';
    }
    let videoObj = (newVideo?newVideo.files[0]:'');
    //alert(`Type of videoPObjs: ${typeof(videoPObjs)}`);

    //Main video
    const storageRefVideo = ref(storage, `/${userFolder}/${currentLoginID}/${newId}/complete.mp4`);
    try{
      if(videoObj){
        skipped.video = false;
        uploadFile(videoObj,storageRefVideo,'video/mp4',newId,true,'complete');
      }
      //No need to upload new files
      if(skipped.video){
        addToUserLib(0,'',newId,'');
      }
    }
    catch(err){
      setError([1,`Failed to call upload function: ${err}`]);
      setLoading(false);
      return;
    }
  }

  async function GetFromId(id){
    const ref = doc(db,'account',id);
    const snap = await getDoc(ref);
    const data = snap.data();
    return data;
}

async function editVideo(id){
  try{
    const videoRef = doc(db, "reels", id);
    const docSnap = await getDoc(videoRef);
    const getDocSnapData = docSnap.data();
    setEditingInfo(getDocSnapData);
}
  catch(err){
    setError([1,`Failed to get editing video: ${err}`]);
  }
  
}
    async function readUserLib(){
        setLoading(true);
        try{
          const docRef = doc(db, "accounts", currentLoginID);
          const docSnap = await getDoc(docRef);
          let user = docSnap.data();
          currentVideoList = user.videos;
          setCurrentView('myVideos');
          
          
          listItems = currentVideoList.map((video,i) =>
          (
            <Grid item xs={videoSize}><div className={videoClass} ><img width="100%" height="100%" alt={video.video_name} src={video.video_cover}></img>
          </div><br/><br/>{video.video_name} 
          <br/><br/><button onClick={() => editVideo(video.video_id)}>{language==='EN'?'Edit or Delete':'更改或删除'}</button></Grid>
          )
          );
          
          setCanShow(false);
          listItems.map((content)=>{
            if(content!==null){
              setCanShow(true);
            }
          });
          
          setLoading(false);
          
        }
        catch (error) {
          setError([1,`Failed to get video of user's folder: ${error}`]);
          }
        return;
    }

if(!currentPlayingInfo){ownerInfo.current='';}
    async function playVideo(id){
      setLoading(true);
      //alert(currentPurchasingVideo);
      ResetTimers(10800000,1440000);
      try{
        const videoRef = doc(db, "reels", id);
        const docSnap = await getDoc(videoRef);
        const getDocSnapData = docSnap.data();
        if(currentLoginID){
          const userRef = doc(db,'accounts',currentLoginID);
          const docSnapUser = await getDoc(userRef);
          const getDocSnapUserData = docSnapUser.data();
          
          createLog(getDocSnapUserData,"watch_video",id);
  
        }
        setCurrentPlayingInfo(getDocSnapData);
        ownerInfo.current = GetFromId(getDocSnapData.owner);
        setNewName(getDocSnapData.video_name);
        setNewDesc(getDocSnapData.video_description);
        setNewTags(getDocSnapData.video_tags ? getDocSnapData.video_tags.map(tag => `${tag}`) : "");
        //+1 play
        await updateDoc(videoRef,{video_watches:getDocSnapData.video_watches+1});
        
        
        //MARKED

        const videoCollRef = collection(db, "reels");

        
        const myQuery = query(videoCollRef, orderBy('video_watches','desc'));
        const relativeDocSnap = await getDocs(myQuery);
        currentVideoList = [];
        if(!relativeDocSnap.empty){
          relativeDocSnap.forEach(doc => {
            const getVideo = doc.data();
            if(getVideo.video_id!==id&&!getVideo.video_hide){currentVideoList.push(getVideo);}
          });

          listItems = currentVideoList.map((video,i) =>
          
          <Grid item xs={videoSize}><div className={videoClass} ><Link to={`/discover?video=${video.video_id}`}><HoverVideoPlayer
          videoSrc={(
            <>
            <source src={video.video_src} type="video/mp4" />
            <source src={video.video_src} type="video/ogg" />
            </>
          )}
          style={{
            width:'100%',height:'100%'
          }}
          sizingMode='container'
          pausedOverlay={(
            <div>
            <div className="coverTitle">
          <h1>{video.video_name}</h1>
          </div>
          </div>
          )}
          /></Link>
          </div></Grid>
          
        );
        }

          

        setLoading(false);
      }catch(error){
        setError([1,`Failed to find video id: ${error}`]);
        setLoading(false);
      }
      return;
    }

    async function starred(id){
      setLoading(true);
      
      //alert(`video id=${id}, user id=${currentLoginID}`);
      try{
        const videoRef = doc(db, "reels", id);
        const docSnap = await getDoc(videoRef);
        const getDocSnapData = docSnap.data();
        const userRef = doc(db,'accounts',currentLoginID);
        const docSnapUser = await getDoc(userRef);
        const getDocSnapUserData = docSnapUser.data();

        //alert(`video starred=${starredListUser}, user id=${currentLoginID}`);
          updateDoc(videoRef,{video_starred:getDocSnapData.video_starred+1});
          setError([0,(language==='EN'?'You have liked the video!':'您已点赞该片！')]);
          createLog(getDocSnapUserData,"like_video",id);
        setLoading(false);
      }catch(error){
        setError([1,`Failed to find like this video: ${error}`]);
        setLoading(false);
      }
      return;
    }

    async function starred(id){
      setLoading(true);
      
      //alert(`video id=${id}, user id=${currentLoginID}`);
      try{
        const videoRef = doc(db, "reels", id);
        const docSnap = await getDoc(videoRef);
        const getDocSnapData = docSnap.data();
        const userRef = doc(db,'accounts',currentLoginID);
        const docSnapUser = await getDoc(userRef);
        const getDocSnapUserData = docSnapUser.data();

        //alert(`video starred=${starredListUser}, user id=${currentLoginID}`);
          updateDoc(videoRef,{video_starred:getDocSnapData.video_starred+1});
          setError([0,(language==='EN'?'You have liked the video!':'您已点赞该片！')]);
          createLog(getDocSnapUserData,"like_video",id);
        setLoading(false);
      }catch(error){
        setError([1,`Failed to find like this video: ${error}`]);
        setLoading(false);
      }
      return;
    }

    async function instantNewName(id){
      setLoading(true);
      
      //alert(`video id=${id}, user id=${currentLoginID}`);
      try{
        const videoRef = doc(db, "reels", id);

        //alert(`video starred=${starredListUser}, user id=${currentLoginID}`);
          updateDoc(videoRef,{video_name:newName ? newName : "",video_tags:newTags ? newTags.split(',').filter(subTag => subTag.trim() !== '') : [],video_description:newDesc ? newDesc : ""});
          setError([0,(language==='EN'?`Saved changes to ${newName}`:`已保存修改：${newName}`)]);
          
        setLoading(false);
      }catch(error){
        setError([1,`Failed to find like this video: ${error}`]);
        setLoading(false);
      }
      return;
    }

    async function instantDelete(id) {
      setLoading(true);
    
      try {
        const videoRef = doc(db, "reels", id);
        const videoSnapshot = await getDoc(videoRef);
    
        if (videoSnapshot.exists()) {
          const videoData = videoSnapshot.data();
          const videoSrc = videoData.video_src;
          const videoTrailer = videoData.video_trailer;
    
          // 删除视频源文件
          if (videoSrc && videoSrc !== "") {
            const videoSrcRef = ref(storage, videoSrc);
            await deleteObject(videoSrcRef);
          }
    
          // 删除视频预告片文件（如果有）
          if (videoTrailer && videoTrailer !== "") {
            const videoTrailerRef = ref(storage, videoTrailer);
            await deleteObject(videoTrailerRef);
          }
    
          // 删除Firestore中的文档
          await deleteDoc(videoRef);
    
          setError([0, language === 'EN' ? `Deleted` : `已删除`]);
        } else {
          throw new Error("Document does not exist");
        }
      } catch (error) {
        setError([1, `Failed to delete this video: ${error.message}`]);
      } finally {
        setLoading(false);
      }
    }
    


async function pop(){
  const index = Math.floor(Math.random()*popLink.length);
  //window.open(popLink[index]);
}

  if(videoID!=currentPlayingInfo.video_id&&videoID&&!loading){
    window.scrollTo({
      top: 0,
      left: 0
    });
    setCurrentPlayingInfo('');
    playVideo(videoID);
    
  }
  

    return (
      
      editinginfo?(
      <Fragment>
        <Header />
        {topMsg?topMsg:null}
        {loading ? <div className="loadingBox"><h2>{language==='EN'?'Please wait...':'加载中...'}{currentUploading!==''?<div>{language==='EN'?'Uploading':'正在上传'} {currentUploading}...</div>:null}{percentNum?percentNum:0}% {language==='EN'?'done':'已完成'}</h2> </div>: null}
      
      <div className="App-loginBox">
              <div>

              {<div>{language==='EN'?'Upload your new complete video:':'新的全片资源'}<p><input type="file" accept="video/*" onChange={(e) => setNewVideo(e.currentTarget)}></input></p><br/><br/></div>}
              {<button  onClick={() => upload(editinginfo.video_id)}>{language==='EN'?'Save changes':'保存更改'}</button>}<br/><br/>
              {<button  onClick={() => Delete(editinginfo.video_id)}>{language==='EN'?'DELETE':'删除'}</button>}<br/><br/>
              {<Link to="/discover"><button onClick={function e(){setCurrentPlayingInfo('');setEditingInfo('');hottestListItems=[];checked.current=false;firstLoadDone.current=false;}}>Back</button></Link>}<br/><br/>
              
          <br/>
              </div></div>
      <Bottom />
      </Fragment>
    ):(

        <Fragment>
          <Header />
          
         <div className='App-loginBox'>
         {topMsg?topMsg:null}
          {loading ? <div className="loadingBox"><h2>{language==='EN'?'Please wait...':'加载中...'}</h2> </div>: null}
          
            {['official','admin'].includes(currentLoginPermission)?<div>
            <input type='file' accept='.txt' onChange={(e)=>setAdLink(e.currentTarget)}></input>
            <br/><button onClick={()=>UploadLinks()}>Upload</button>
            <br/><button onClick={()=>DownloadLinks()}>Download</button>

            </div>:null}
            {currentPlayingInfo===''?<div className='discover'>
          {currentView==="myVideos"?
          <div>
          {!canShow?<div><h5>{language==='EN'?'You do not have any video yet...':'您还没有视频哦...'}<Link to="/upload">{language==='EN'?'Upload one today!':'上传您的第一支视频！'}</Link></h5></div>:
          <tbody>
            <h1>{language==='EN'?'Your videos':'您的视频'}</h1>
            <Grid container rowSpacing={0.5} columnSpacing={0}>{listItems}</Grid>
          </tbody>
          }
        </div>:null}
          
    
        <br/>
          <br/><h1>{language==='EN'?'Our hottest videos: ':'热门视频'}</h1>
          <br/><tbody><Grid container rowSpacing={0.7} columnSpacing={0.5}>{hottestListItems}</Grid></tbody>
          {lim<videoCountTotal?<div><br/>
          <button className='smBtn' onClick={function e(){ResetList(lim+18);setLimit(lim+18);}}>
            {language==='EN'?'Show More':'观看更多'}</button><br/></div>:<div><br/><h1>{language==='EN'?'No More':'没有更多了'}</h1><br/></div>}
            
          <br/>
        </div>
          :<div>
            <p style={{textAlign:'left',paddingLeft:'2.05vw',paddingTop:'1vw'}}><span className='videoTitle'>{currentPlayingInfo.video_name}</span>{currentLoginPermission == 'official' &&<div>
              <input value={newName} onChange={(e) => setNewName(e.target.value)}/>
              <input  value={newDesc} onChange={(e) => setNewDesc(e.target.value)}/>
              <input  value={newTags} onChange={(e) => setNewTags(e.target.value)}/>
              <button className='tagSelectBtn' onClick={()=>instantNewName(currentPlayingInfo.video_id)}>更改</button>
              <button className='tagSelectBtn' onClick={()=>instantDelete(currentPlayingInfo.video_id)}>删除</button></div>}{'　　'}{'　　'}
            <img style={{height:'0.9vw',width:'1.3vw'}} src={process.env.PUBLIC_URL+`/ui/eye.png`}/>
            <span style={{fontSize:'1vw',paddingBottom:'0.8vw'}}>{'　'}{currentPlayingInfo.video_watches}{'　'}</span><button style={{width:'0.9vw',height:'0.9vw',backgroundColor:'#F5F5F5'}} onClick={()=>starred(currentPlayingInfo.video_id)}><img className='likebtn' style={{height:'0.9vw',width:'0.9vw',zIndex:1}} src={process.env.PUBLIC_URL+`/ui/like.png`}/></button><span style={{fontSize:'1vw'}}>{'　'}{currentPlayingInfo.video_starred}{'　'}</span></p>
           

           <div><br/><div className='playingBackground'><div className='playingVideo'>
           <div className='watermark'>{`Uploaded on ${convertTimestamp(currentPlayingInfo.video_upload_date)} to lan-gui-fang.com`}
           <br/><img style={{height:'7.24vw',width:'20vw'}} src={process.env.PUBLIC_URL+`/logo128.png`}/>
           </div>
    <video preload="auto" autoplay controls playsinline controlsList='nodownload' 
    onPause={()=>pop()}
    >
      <source src={currentPlayingInfo.video_src} type="video/mp4"></source><source src={currentPlayingInfo.video_src} type="video/ogg"></source></video></div>
    </div>
            <br/><br/>
            {report?<div><button onClick={()=>setReport(false)}>{language==='EN'?'Cancel':'取消举报'}</button><br/><br/><input type="text" placeholder={language==='EN'?'Reason of report, copyright, violent, kids, etc.':'举报原因，比如侵权，暴力，未成年等'} onChange={(e)=>setReason(e.target.value)}></input><br/><br/><button onClick={()=>HandleReport()}>Submit</button></div>:<button onClick={()=>setReport(true)}>{language==='EN'?'Report':'举报视频'}</button>}
            <br/></div>
            
            {/* {<div><br/><h1>{language==='EN'?'You maybe like: ':'猜你喜欢'}</h1><br/>
            <Grid container  rowSpacing={0.7} columnSpacing={0.5}>{listItems}</Grid><br/>
            <br/>
            {lim<videoCountTotal?<div><br/>
            <button onClick={function e(){ResetList(lim+18);setLimit(lim+18);}}>
              {language==='EN'?'Show More':'观看更多'}</button><br/></div>:<div><br/><h1>{language==='EN'?'No More':'没有更多了'}</h1><br/></div>}
            <br/>
            <Link to="/discover"><button onClick={()=>setCurrentPlayingInfo('')}>{language==='EN'?'Back':'返回'}</button></Link><br/><br/></div>} */}
            </div>}
            </div>
            <br/><br/>
            <br/><br/>
            <Bottom />
        </Fragment>
      ));
}




export default ViewReel;