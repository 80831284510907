import './App.css';
import React, { useState, useEffect, Fragment,useRef } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from "./server/firebase";
import {doc,updateDoc,setDoc,getDocs,getDoc,collection,where,query,orderBy, limit} from "firebase/firestore";
import HoverVideoPlayer from 'react-hover-video-player';
import Grid from '@mui/material/Unstable_Grid2'; 
import isMobileDevice from './getUserClient';

const mobile = isMobileDevice();
function HottestVideo() {
  const [error, setError] = useState([]);
  const [videoMainPage, setMP] = useState('');
  const errorMsg =()=>{
      return(
            (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
      )
    }
    const topMsg = errorMsg();

  const firstTimeLoaded = useRef(false);
  const videoSize = mobile?4:2;
  const videoClass = mobile?'videoContainer-M':'videoContainer'
  async function FirstTimeLoad(){
    try{
      const videoCollRef = collection(db, "videos");
      
          
      const myQuery = query(videoCollRef, where('video_hide','==',false),orderBy('video_watches','desc'),limit(12));
      const relativeDocSnap = await getDocs(myQuery);
      let currentVideoListNew = [];
      if(!relativeDocSnap.empty){
        relativeDocSnap.forEach(doc => {
          const getVideo = doc.data();
          currentVideoListNew.push(getVideo);//alert(`getVideo:${getVideo.video_id}`)
        
          
          
        });

        let videoMaps = currentVideoListNew.map((video,i) =>
        
        <Grid item xs={videoSize}>
       
          <div className={videoClass} >
          <Link to={`/discover?video=${video.video_id}`}><HoverVideoPlayer
        videoSrc={(
          <>
          <source src={video.video_trailer?video.video_trailer:video.video_src} type="video/mp4" />
          <source src={video.video_trailer?video.video_trailer:video.video_src} type="video/ogg" />
          
          </>
        )}
        style={{
          width:'100%',height:'100%'
        }}
        preload="auto"
        sizingMode='container'
        pausedOverlay={(
          <div>
            <div className="coverTitle">
          <h1>{video.video_name}</h1>
          <p>{video.video_description==="No description"?null:video.video_description}</p>
          </div>
          </div>
            
        )}
        />
        </Link></div></Grid>);
      setMP(videoMaps);

      }
      else{
      setError([1,`Empty`]);

      }
  
    }
    catch(err){
      setError([1,`Failed to load default video list: ${err}`]);
    }
  }

  if(!firstTimeLoaded.current){
    firstTimeLoaded.current=true;
    FirstTimeLoad();
  }

    return (
    
        <Fragment>
          <div>
          {topMsg?topMsg:null}
          <tbody><Grid container rowSpacing={0.7} columnSpacing={0.5}>{videoMainPage}</Grid></tbody>
          </div>
        </Fragment>
      );
}



export default HottestVideo;