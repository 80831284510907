import './App.css';
import React, { useState, useEffect, Fragment,useRef } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from "./server/firebase";
import {doc,updateDoc,setDoc,getDocs,getDoc,collection,where,query,orderBy, limit, increment} from "firebase/firestore";

let videoMaps;
function Promotions() {
  const [error, setError] = useState([]);
  const [videoMainPage, setMP] = useState('');
  const errorMsg =()=>{
      return(
            (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
      )
    }
    const topMsg = errorMsg();

  const firstTimeLoaded = useRef(false);

  async function click(current){
    const ref = doc(db, "promotions",current);
    updateDoc(ref,{click:increment(1)});
  }

  async function FirstTimeLoad(){
    try{
      const videoCollRef = collection(db, "promotions");
      
          
      const myQuery = query(videoCollRef);
      const relativeDocSnap = await getDocs(myQuery);
      let currentVideoListNew = [];
      if(!relativeDocSnap.empty){
        relativeDocSnap.forEach(doc => {
          const getVideo = doc.data();
          currentVideoListNew.push(getVideo);//alert(`getVideo:${getVideo.video_id}`)
        });

        videoMaps = currentVideoListNew.map((video,i) =>
          <li onClick={()=>click(video.name)} >
          <Link to={video.src} target='_blank'>❤️{video.name}
        </Link></li>);
        setMP(videoMaps);
      }
      else{
      //setError([1,`Empty`]);

      }
  
    }
    catch(err){
      setError([1,`Failed to load promotions list: ${err}`]);
    }
  }

  if(!firstTimeLoaded.current){
    firstTimeLoaded.current=true;
    FirstTimeLoad();
  }

    return (
    
        <Fragment>
          <div className='pList'>
          {topMsg?topMsg:null}
          <h2>友情链接 Promotions:</h2>
          <ul>{videoMainPage}</ul>
          </div>
        </Fragment>
      );
}



export default Promotions;