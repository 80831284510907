import '../App.css';
import React,{ useState, useEffect, Fragment } from 'react';
import Header from '../header';
import Bottom from '../bottom';
import { language } from '../header';
import HottestVideo from '../hottestVideos';
import NewestVideo from '../newestVideos';
import { useSearchParams,useNavigate } from "react-router-dom";
import collectLinkTag from '../linkSource';
import { db } from '../server/firebase';
import { collection, getDocs } from 'firebase/firestore';


const MainPage = () => {   
  
  const [queryParameters] = useSearchParams();
  const [cat,setCat] = useState('');
  const source = queryParameters.get("from");
  const urlCat = queryParameters.get("cat");
  const [catsList,setCatsList] = useState([]);
  
  useEffect(() => {
    if (!localStorage.getItem('from') && source) {
      localStorage.setItem("from", JSON.stringify(source));
      collectLinkTag(source);
    }
  }, [source]);
  

  useEffect(() => {
    const fetchCatNames = async () => {
      const querySnapshot = await getDocs(collection(db, "keywords"));
      let items = [];
      querySnapshot.forEach((doc) => {
        // 假设每个文档中都有一个rank属性
        const data = doc.data();
        items.push({ name: data.name, rank: data.rank });
      });
  
      // 根据rank属性对items数组进行排序，从高到低
      items.sort((a, b) => b.rank - a.rank);
  
      // 从排序后的items数组中提取name属性，构建names数组
      const names = items.map(item => item.name);
  
      // 保留原始逻辑，将''和'new'添加到names数组的开头
      names.unshift('', 'new');
  
      setCatsList(names);
    };
  
    fetchCatNames();
  }, []);
  

  useEffect(() => {
    if (urlCat) {
      setCat(urlCat); // 当urlCat有值时更新cat的值
    }
  }, [urlCat]);

  function CatButtons({ catNames }) {
    const navigate = useNavigate(); // 创建导航函数
  
    const handleButtonClick = (name) => {
      // 更新URL的cat参数
      navigate(`?cat=${name}`);
      setCat(name); 
    };
  
    return (
      <div className='cat-btns-line'>
      <div className="cat-btns-container">
        {catNames.map((name) => (
          <button
            key={name}
            className={`cat-btns ${cat === name ? "active" : ""}`}
            onClick={() => handleButtonClick(name)} // 使用handleButtonClick来处理点击事件
          >
            {name === '' ? "全部" : name === 'new' ? "最新" : name}
          </button>
        ))}
      </div>
      </div>
    );
  }
  


  
  
  return (     
  <div>
  <Fragment>
    <Header />
    <CatButtons catNames={catsList} />
    
    <div className="App-mainPage">
    
    

    <NewestVideo cat={cat}/>
    {/*<div class='line_container'><h2>{language==='EN'?'NEWEST UPDATES':'最新影片'}</h2></div>

 
     
     
    <div class='line_container'><h2>{language==='EN'?'FEATURES':'热门影片'}</h2></div>

  <HottestVideo />*/}
    
   
   
    
  </div>
    <Bottom /> 
  </Fragment>
          
  </div>
    ); 
  }; 
export default MainPage;