
import {doc,updateDoc} from "firebase/firestore";
import { db } from "./server/firebase";


function GoogleTranslate(content,id){
  
    let tolang = 'zh-CN'
    
    //console.log(`[GTDEBUG]Translating ${content} for ${id}`);
    const api_key = 'AIzaSyB3e_WSGUJfLq-TmoqtfPf6Doaf6PNiAGI';
    const ref = doc(db, "videos",id);
    let url = `https://translation.googleapis.com/language/translate/v2?key=${api_key}`;
    url += '&q=' + encodeURI(content);
    url += `&target=${tolang}`;
    //console.log(`[GTDEBUG]Fetch URL ${url}`);
    fetch(url, { 
      method: 'get',
      headers: {
        "content-type": "application/json",
        accept: "application/json"
      }
    })
    .then(res => res.json())
    .then((response) => {
        const result = response.data.translations[0];
        try{
          updateDoc(ref,{video_name:result.translatedText});
        }
        catch(err){
          console.log(`[GTDEBUG]Error: ${err}`);
        }
        
        
    })
    .catch(err => {
      console.log(`[GTDEBUG]Error: ${err}`);
    });
    
  return;
}

   
export default GoogleTranslate;