import './App.css';
import React, { useState, useEffect, Fragment,useRef } from 'react';
import { Link,useNavigate,useSearchParams,useLocation } from 'react-router-dom';
import { db, storage } from "./server/firebase";
import {doc,updateDoc,setDoc,getDocs,getDoc,collection,where,query,orderBy, limit} from "firebase/firestore";
import HoverVideoPlayer from 'react-hover-video-player';
import Grid from '@mui/material/Unstable_Grid2'; 
import { language } from './header';
import isMobileDevice from './getUserClient';
import Header from './header';
import Bottom from './bottom';

let videoCountTotal = 0;
let currentVideoListNew = [];
let loadedVideoList = [];
let singlePage = 12;
const mobile = isMobileDevice();
function NewestReels({ cat = '' }) {
  const [error, setError] = useState([]);
  const [videoMainPage, setMP] = useState('');
  const lim = useRef(singlePage);
  const filteredGroup = useRef([]);
  const [inputString, setInputString] = useState('');
  const [displayMP, setDisplayMP] = useState([]);
  const [queryParameters] = useSearchParams();
  const savedCat = useRef('');
  
  const searchTags = queryParameters.get("search") ? queryParameters.get("search") : "";
  const displayPages = queryParameters.get("display");

  const errorMsg =()=>{
    return(
          (error[1]!==''&&error[1]!==undefined)?<div className="msgBox"><div className={error[0]===1?"errorBox":"processBox"}>{error[0]===1?"⚠️":"✅"}{error[1]}</div></div>:null
    )
  }
    const topMsg = errorMsg();
  const videoSize = mobile?4:3;
  const videoClass = mobile?'videoContainer-M':'videoContainer'
  const firstTimeLoaded = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const search = () => {
    
    
    // 分割输入字符串为数组，去除空字符串
    const n = inputString.split(',').filter(tag => tag.trim() !== '');
    lim.current = singlePage;
    //navigate(`?cat=${cat}&display=${lim.current}&search=${inputString}`);
    videoCountTotal = currentVideoListNew.length;
    //alert(`Loaded videos search: ${videoCountTotal}`);
    // 在currentVideoListNew中筛选符合条件的视频
    filteredGroup.current = currentVideoListNew.filter(video => 
      video.video_tags && (n.every(tag => video.video_tags.includes(tag)) || n.every(tag => video.video_name.includes(tag)))
    );


    RebuildMaps(filteredGroup.current,lim.current);

  };


  useEffect(() => {
    //if (searchTags) {setInputString(searchTags);}
    
    //navigate(`?cat=${cat}&display=${singlePage}&search=${searchTags}`);
    
    FirstTimeLoad();
    
  }, [cat]);

  async function FirstTimeLoad(){
    try{
      const videoCollRef = collection(db, "reels");
      const videoCount = await getDocs(query(videoCollRef));
      loadedVideoList = [];
      if(!videoCount.empty){
        videoCount.forEach(doc => {
          const getVideoT = doc.data();
          loadedVideoList.push(getVideoT);
        });
      }
      videoCountTotal = loadedVideoList.length;
      //alert(`Loaded videos: ${videoCountTotal}`);
          
      

      let myQuery; 
      
      if(cat==''||cat == 'new'){
        myQuery = query(videoCollRef, where('video_hide','==',false),orderBy('video_upload_date','desc'));
      }
      else{
        myQuery = query(videoCollRef, where('video_hide','==',false),orderBy('video_upload_date','desc'),where('video_tags','array-contains',cat));
      }

      const relativeDocSnap = await getDocs(myQuery);
      currentVideoListNew = [];
      if(!relativeDocSnap.empty){
        relativeDocSnap.forEach(doc => {
          const getVideo = doc.data();
          currentVideoListNew.push(getVideo);
        });
        filteredGroup.current = currentVideoListNew;
        RebuildMaps(filteredGroup.current,lim.current);
        
        
        setError([]);
      }
      else{
      setError([1,`暂时没有影片`]);
      
      }
  
    }
    catch(err){
      setError([1,`Failed to load default video list: ${err}`]);
    }
  }

  function generatePageButtons() {
    const totalPages = Math.ceil(filteredGroup.current.length / singlePage);
    const currentPage = lim.current / singlePage;
    const pageButtons = [];
    let startPage = Math.max(1, currentPage - 4);
    let endPage = Math.min(totalPages, currentPage + 4);
  
    // 显示前5页
    for (let i = 1; i <= Math.min(5, totalPages); i++) {
      pageButtons.push(<button key={i} disabled={i === currentPage} className={i === currentPage ? 'pageBtn active' : 'pageBtn'} onClick={() => setPage(i)}>{i}</button>);
    }
  
    // 如果当前页前后范围超过了前5页，添加省略号
    if (startPage > 6) {
      pageButtons.push(<span key='startEllipsis'>...</span>);
    }
  
    // 显示当前页前后5页
    for (let i = startPage; i <= endPage; i++) {
      if (i > 5 && i < totalPages - 4) { // 避免与前5页和后5页重复
        pageButtons.push(<button key={i} disabled={i === currentPage} className={i === currentPage ? 'pageBtn active' : 'pageBtn'} onClick={() => setPage(i)}>{i}</button>);
      }
    }
  
    // 如果当前页后范围距离最后5页有间隔，添加省略号
    if (endPage < totalPages - 5) {
      pageButtons.push(<span key='endEllipsis'>...</span>);
    }
  
    // 显示最后5页
    for (let i = Math.max(totalPages - 4, 6); i <= totalPages; i++) {
      pageButtons.push(<button key={i} disabled={i === currentPage} className={i === currentPage ? 'pageBtn active' : 'pageBtn'} onClick={() => setPage(i)}>{i}</button>);
    }
  
    return pageButtons;
  }
  
  function setPage(pageNumber) {
    //const searchParams = new URLSearchParams(location.search);
    
    lim.current = (pageNumber) * singlePage;
    //searchParams.set('display', lim.current);
    //navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    RebuildMaps(filteredGroup.current, lim.current);
  }
  

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // 生成从 0 到 i 的随机索引
        const j = Math.floor(Math.random() * (i + 1));
        // 交换元素位置
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

  function RebuildMaps(list,limp){
    //alert(`From ${limp - singlePage} to ${limp}`);

    let newlist = cat !== ""? list : shuffleArray([...list]);

    let videoMaps = newlist.map((video,i) =>
        (i<limp && i>=limp - singlePage) ? <Link to={`/reel?video=${video.video_id}`}>
        
       
        <div className={videoClass} >
        <HoverVideoPlayer
      videoSrc={(
        <>
        <source src={video.video_trailer?video.video_trailer:video.video_src} type="video/mp4" />
        <source src={video.video_trailer?video.video_trailer:video.video_src} type="video/ogg" />
        </>
      )}
      style={{
        width:'100%',height:'100%'
      }}
      loadingOverlay={
        <div className="loading-overlay">
        </div>
      }
      preload="auto"
      sizingMode='container'
      id={i}
      />
     </div>
      <div>
          <div className="coverTitle">
        <h1>{video.video_name}</h1>
       {/*<p>{video.video_description==="No description"?null:video.video_description}</p>*/}
       {<span>{`${video.video_watches} Views | ${video.video_starred} Likes`}</span>}
        </div>
        </div> </Link> : null
        );
      if(videoMaps.length>0){
        setMP(videoMaps);
        setError([]);
      }
      else{
        setError([1,`暂时没有影片`]);
      }
      
      
  }

    return (
    
      <Fragment>
        <Header/>
        <div className='App-mainPage'>
      <div>
        {topMsg ? topMsg : null}
        <div className="searchContainer">
          <input className='searchInput'  placeholder={cat == '' || cat == 'new'?`搜索全部影片`:`在${cat}影片中搜索`} type='text' onChange={(e) => setInputString(e.target.value)}/>  
          <button className='searchBtnT' onClick={()=>search()}>Search</button>
        </div>
        <div className='video-grid-container'>
          {videoMainPage}
        </div>
      </div>
      <br/>
      <div className="pagination-container">
        {lim.current > singlePage && (
          <button className='smBtn' onClick={() => {lim.current -= singlePage; RebuildMaps(filteredGroup.current, lim.current);}}>
            {'<'}
          </button>
        )}
        
        {generatePageButtons()}
        
        {lim.current < filteredGroup.current.length && (
          <button className='smBtn' onClick={() => {lim.current += singlePage; RebuildMaps(filteredGroup.current, lim.current);}}>
            {'>'}
          </button>
        )}
      </div>
      </div>
      <Bottom/>
    </Fragment>
    
      );
}



export default NewestReels;