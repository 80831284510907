import './App.css';
import React,{ Fragment } from 'react';
import Promotions from './promotion';

const Bottom = () => {   
    return (     
    <Fragment>
      
      <div className="App-bottom">
        <Promotions />
      <br/>
      {/*  <iframe
   style={{backgroundColor: "white",
   width:"69.23vw",
   height:"6.3vw",
   scrolling:"no",
   frameborder:0,
   allowtransparency:true,
   marginheight:0,
   marginwidth:0,

  }}
   name="spot_id_1234567"
   src="https://a.adtng.com/get/10013899">
</iframe>*/}
     
        
      </div>
      <div className='copyright'>
        <p>Contact & Abuse: NONE of the media is hosted by this site. This site only provides search results. If you have any question to be addressed, please make sure to contact where the media is actually hosted. Our service is a platform for users to search videos from content providers, which according to US code:47 U.S.C. § 230, a Provision of the Communication Decency Act, that "No provider or user of an interactive computer service shall be treated as the publisher or speaker of any information provided by another information content provider.</p>
      </div>
    </Fragment>
      ); 
    }; 
  export default Bottom;

