import { db } from './server/firebase';
import { doc,updateDoc, setDoc,getDoc,collection,where,query, getDocs } from "firebase/firestore";
import React, { useState, Fragment,useRef } from 'react';
import { language } from './header';
function FloatAD() {

    const [showLeft,setShowLeft] = useState(true);
    const [showRight,setShowRight] = useState(true);
    const [showMiddle,setShowMiddle] = useState(true);
    const isFirstLoad = useRef(false);
    const leftAds = useRef('');
    const rightAds = useRef('');
    
    const [ads,setAds] = useState('');

if(!isFirstLoad.current){
    isFirstLoad.current = false;
    FirstLoad();
}


    async function FirstLoad(){
        const ref = doc(db,'ad','floatad');
        const snap = await getDoc(ref);
        const links = snap.data().links;
        const index = Math.floor(Math.random()*links.length);
        //const vLinks = snap.data().vLinks;
        //const indexLeft = Math.floor(Math.random()*vLinks.length);
        //const indexRight = Math.floor(Math.random()*vLinks.length);
        //leftAds.current = vLinks(indexLeft);
        //rightAds.current = vLinks(indexRight);
        
        setAds(links[index]);
    }

      return (
      
          <Fragment>
            
              {showLeft&&leftAds.current!==''?
            <div className="floatADLeft">  
            <div dangerouslySetInnerHTML={{__html: leftAds.current}} />
                
                <br/>
            <button onClick={()=>setShowLeft(false)}>{language==='EN'?'close':'关闭'}</button>
               
            </div>
            :null}
              {showRight&&rightAds.current!==''?
            <div className="floatADRight"> 
            <div dangerouslySetInnerHTML={{__html: rightAds.current}} />
                
                <br/>
            <button onClick={()=>setShowRight(false)}>{language==='EN'?'close':'关闭'}</button>
              
            </div>
            :null}


            {showMiddle&&ads!==''?
            <div className="floatADMiddle">
            <div dangerouslySetInnerHTML={{__html: ads}} />
                
                <br/>
            <button onClick={()=>setShowMiddle(false)}>{language==='EN'?'close':'关闭'}</button>
            </div>
            :null}
              
              
          </Fragment>
        );
  }
  
  
  
  export default FloatAD;