import React, { useState } from "react"; 
import { BrowserRouter, Route, Link } from "react-router-dom"; 
import Grid from '@mui/material/Unstable_Grid2'; 

export let confirmOver18V = false;

function Over18Pop() {   
    const [isKid,setIsKid] = useState(false);
    const [isClicked,setIsClick] = useState(false);

    
    

    async function Confirm(){
        localStorage.setItem('confirmOver18',JSON.stringify(true));
        confirmOver18V = true;
    }
    

    return (     
        (!isClicked&&!confirmOver18V)?(
    <div className="pop-up-over-18">
          <h1>Please confirm your age</h1>
          <br/>
    <button className="btnA" onClick={function(e){setIsClick(true);setIsKid(false);Confirm();}}>I'm 18</button><br/><br/>
    <Link to='about:blank'><button className="btnD">I'm under 18, I'm out</button></Link><br/><br/>
    <p>This website contains age-restricted materials including nudity and explicit depictions of sexual activity.</p>
    </div>
 )  
     :null); 
} 



export default Over18Pop;