export function DateToLogDay(e,days=0){
    let d = new Date();
    d.setDate(e.getDate()+days);
    let y = d.getFullYear();
    let m = d.getMonth()+1;
    let day = d.getDate()+1;
    if(m<=9){m=`0${m}`;}
    if(day<=9){day=`0${day}`;}
    const result = parseInt(`${y}${m}${day}`);
    return result;
}

export function DateToLogMonth(e,days=0){
    let d = new Date();
    d.setDate(e.getDate()+days);
    let y = d.getFullYear();
    let m = d.getMonth()+1;
    
    if(m<=9){m=`0${m}`;}
    
    const result = parseInt(`${y}${m}`);
    return result;
}

export function InputDateToDate(d){
    let dateList = d.split('-');
    let result = new Date(dateList[0], dateList[1]-1, dateList[2]-1);
    return result;
}

export function getCurrentDate() {
    // 获取当前日期
    const currentDate = new Date();
  
    // 获取年、月、日
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
  
    // 构建 'YYYY-MM-DD' 格式的日期字符串
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }




