import './App.css';
import React,{ Fragment, useState } from 'react';
import { Link } from "react-router-dom"; 
import { currentLoginAccount,currentLoginID,currentLoginPermission } from './component/login';
import Navbar from './navbar';
import { confirmOver18V } from './over18dec';
import Over18Pop from './over18dec';
import permissionSign from './permissionSign';
import { db } from './server/firebase';
import { doc,updateDoc, setDoc,getDoc,collection,where,query, getDocs } from "firebase/firestore";
import FloatAD from './floatAds';

// Set timeout vars.
let warningTimer;
let timeoutTimer;
export let language = 'CN';

export let timerStarted = false;
// Show idle timeout warning dialog.

  
  // Reset timers.
  export function ResetTimers(timoutWarning=840000,timoutNow=900000) {
    clearTimeout(warningTimer);
    clearTimeout(timeoutTimer);
    //alert(`Reset a timer: warning:${warningTimer} warning:${timeoutTimer} cleared`);
    StartTimers(timoutWarning,timoutNow=900000);
  }
  
  // Start timers.
  export function StartTimers(timoutWarning=840000,timoutNow=900000) {
    //alert(`Started a timer: ${timoutNow/1000} secs`);
    timerStarted=true;
  }
  
  
if(!timerStarted){
  
  timerStarted=true;
  StartTimers();
}
  

const logoPath = './logo128.png';
const Header = () => {  
  
  const [loading, setLoading] = useState(false);
  //check local storage
  ResetTimers();
  const localLoginName = JSON.parse(localStorage.getItem('loginName'));
  const localLoginID = JSON.parse(localStorage.getItem('loginID'));
  const localPassword = JSON.parse(localStorage.getItem('psw'));
  const localConfirmOver18 = JSON.parse(localStorage.getItem('confirmOver18'));
  const localGetLangSetting = JSON.parse(localStorage.getItem('lang'));
  

if(localConfirmOver18){confirmOver18V=true;}else{confirmOver18V=false;}
if(localGetLangSetting){language=localGetLangSetting;}else{language='EN';}
if(localLoginName&&localLoginID&&(currentLoginAccount===''||currentLoginID===''||currentLoginPermission==='')){
  checkPermission(localLoginID);
}

async function checkPermission(id){
  const cRef = doc(db,'accounts',id);
  const cSnapShot = await getDoc(cRef);
  const cSSData = cSnapShot.data();
  
  const cPSW = cSSData.password;
  if(cPSW!==localPassword){
    alert(`Invalid login, please go back to homepage!`);
    localStorage.clear();
  }
  else{
    currentLoginAccount = localLoginName;
    currentLoginID = localLoginID;
    currentLoginPermission = permissionSign(cSSData.permission_groups);
  }

}


function handleChange(){
  //false = CN  true = EN \
  setLoading(true);
  if(language === 'CN'){
    language = 'EN';
  }
  else{
    language = 'CN';
  }
  window.location.reload();
  
  localStorage.setItem("lang", JSON.stringify(language));
  setLoading(false);
}
//alert(`id_local:${localLoginID},id_online:${currentLoginID},name_local:${localLoginName},name_online:${currentLoginAccount},permission_local:${localLoginPermission},permission_online:${currentLoginPermission}`);
//<div className='search'><input className='searchInput' type="text" placeholder='Search...'></input></div>
    return (     
    <div>
    <Fragment>
      <Over18Pop />
      <div className="App-header" id='header'>
  
  <div className="center-container">
  <Link to="/"><img className='App-header-logo' src={process.env.PUBLIC_URL+logoPath} alt='home'/></Link>  
  <div className='searchblank' />
    <div className='switch-lang' onClick={()=>handleChange()}>  
      {'EN/CN'}
    </div>
    <div>
          <ul className='user-pic'>
          <li><Link to="/login"><img src={process.env.PUBLIC_URL+'/ui/acc.png'}/></Link></li>
          </ul>
          </div>
  </div>
</div>
        
        
        <Navbar />
        
    </Fragment>
            
    </div>
    
      ); 
    }; 
  export default Header;