import React,{ useState } from "react"; 
import { BrowserRouter, Route, Link,NavLink,useLocation } from "react-router-dom"; 
import { currentLoginID, currentLoginPermission } from "./component/login";
import './App.css';
import { language } from "./header";

function Navbar() {
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    return (
        <div>
            <div className="App-navbar">
                <div className="horizontalmenu">
                    <ul>
                        <li className={isActive('/') ? 'active-nav-item' : ''}>
                            <Link to="/"> {language === 'EN' ? 'HOME' : '首页'} </Link>
                        </li>
                        <li className={isActive('/reels') ? 'active-nav-item' : ''}>
                            <Link to="/reels"> {language === 'EN' ? 'REELS' : '短视频'} </Link>
                        </li>
                        {currentLoginID && (
                            <li className={isActive('/upload') ? 'active-nav-item' : ''}>
                                <Link to="/upload"> {language === 'EN' ? 'UPLOAD' : '上传视频'} </Link>
                            </li>
                        )}
                        {['official', 'admin'].includes(currentLoginPermission) && (
                            <li className={isActive('/DwI9spy3bYC533sjXsVo') ? 'active-nav-item' : ''}>
                                <Link to="/DwI9spy3bYC533sjXsVo"> {language === 'EN' ? 'ADMIN' : '管理员后台'} </Link>
                            </li>
                        )}
                        <li className={isActive('/submit') ? 'active-nav-item' : ''}>
                            <Link to="/submit"> {language === 'EN' ? 'COOPERATE' : '互推合作'} </Link>
                        </li>
                        {/* 这里可以继续添加其他链接 */}
                    </ul>
                </div>
            </div>
        </div>
    );
}




export default Navbar;