import { db } from "./server/firebase";
import {doc,updateDoc,increment} from "firebase/firestore";


export default async function collectLinkTag(tag){
    try{
        const ref = doc(db,'ad','linkSource');
    
        const newSource = {
            [tag]:increment(1)
        }
        updateDoc(ref,newSource);
    }
    catch(err){
        console.log(`Failed to add link source : ${err}`);
    }
    
}


